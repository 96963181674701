import {createBehavior} from '@area17/a17-behaviors';

const newsletterCta = createBehavior('newsletterCta',
    {
        success() {
            this.flipFlop = !this.flipFlop;
            this.$node.classList.toggle('accent-secondary', !this.flipFlop);
            this.$node.classList.toggle('accent-primary', this.flipFlop);
        },
    },
    {
        init() {
            this.$form = this.getChild('form');
            this.flipFlop = false;

            this.$form.addEventListener('formAjax:success', this.success, false);
        },
        destroy() {
            this.$form.removeEventListener('formAjax:success', this.success);
        },
    },
);

export default newsletterCta;
