import {createBehavior} from '@area17/a17-behaviors';
import $ from 'jquery';
import {getCookie, setCookie} from 'tiny-cookie';

const gatedPdfForm = createBehavior(
    'gatedPdfForm',
    {
        formatNumber() {
            function phoneFormatter() {
                $('.phone').on('input', function() {
                    let number = $(this).val().replace(/[^\d]/g, '');
                    if (number.length === 7) {
                        number = number.replace(/(\d{3})(\d{4})/, '$1-$2');
                    } else if (number.length === 10) {
                        number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                    }
                    $(this).val(number);
                });
            }
        },

        handleDownload() {
            $(this.form).submit();
        },

        handleFormSubmission() {
            const that = this;
            $(this.form).submit(function(ev) {
                // Prevent the form from actually submitting
                ev.preventDefault();

                // Hide any previous errors
                that.$node.querySelectorAll('[data-error]').forEach(function(el) {
                    el.classList.add('hidden');
                    el.innerHTML = '';
                });
                that.resultDiv.innerHTML = '';

                // Send it to the server
                $.post({
                    url: '/',
                    dataType: 'json',
                    data: $(this).serialize(),
                    success: function(response) {
                        that.form.classList.add('hidden');
                        that.$node.querySelector('[data-result]').innerHTML = 'Please check your email for the download link. If you do not receive an email, please contact us at' +
                            ' <a href="mailto:foundationcomms@uschamber.com">foundationcomms@uschamber.com</a>.';

                        // store the form data as a cookie
                        that.storeInfoAsCookie(response.submission);

                    },
                    error: function() {
                        that.$node.querySelector('[data-result]').innerHTML = 'An unexpected error has occurred. Please try again later.';
                    },
                });
            });
        },

        storeInfoAsCookie(submission) {
            const cookieData = {};

            for (const property of this.cookieFields) {
                let currentValue = submission;
                const propertyPath = property.split('.');

                try {
                    // Navigate the property path safely
                    for (const key of propertyPath) {
                        if (currentValue && typeof currentValue === 'object' && key in currentValue) {
                            currentValue = currentValue[key];
                        } else {
                            // Property doesn't exist in the path
                            currentValue = undefined;
                            break;
                        }
                    }

                    // Store the value if it exists
                    if (currentValue !== undefined) {
                        cookieData[property] = currentValue;
                    }
                } catch (error) {
                    console.log(`Error accessing property ${property}: ${error.message}`);
                }
            }

            setCookie(this.cookieName, JSON.stringify(cookieData), {secure: true, sameSite: 'Strict'});
        },

        prefillForm(cookieData) {
            const formData = JSON.parse(cookieData);

            for (let name in formData) {
                // strip any single quotes from name
                const strippedName = name.replace(/'/g, '');
                const selector = `[name="${strippedName}"]`;
                const input = this.$node.querySelector(selector);
                if (input) {
                    input.value = formData[name];
                }
            }

        },

        resetCsrfToken() {
            const endpoint = '/actions/forms/forms/get-csrf-token';
            const that = this;
            $.get({
                url: endpoint,
                dataType: 'json',
                success: function(response) {
                    console.log('token', response.csrfTokenValue);
                    that.$node.querySelector('[name="CRAFT_CSRF_TOKEN"]').value = response.csrfTokenValue;
                },
            });
        },

    },
    {
        init() {
            this.form = this.$node.querySelector('form');
            this.resultDiv = this.$node.querySelector('[data-result]');
            this.openModalId = this.$node.dataset.openmodalId;
            this.cookieName = 'formGatedPdf';
            this.cookieFields = [
                'fromEmail',
                'message[\'firstName\']',
                'message[\'lastName\']',
                'message[\'jobTitle\']',
                'message[\'organization\']',
                'message[\'phone\']',
            ];

            this.resetCsrfToken();

            this.handleFormSubmission();

            const cookieData = getCookie(this.cookieName);

            if (cookieData) {
                this.prefillForm(cookieData);
                // $(this.form).submit();
            }
        },
    });

export default gatedPdfForm;