import {createBehavior} from '@area17/a17-behaviors';

const dropDown = createBehavior(
    'dropDown',
    {
        initSelects() {
            this.selects.forEach((elem) => {
                elem.querySelector('select').addEventListener('change', (e) => {
                    this.setValues();
                });
            });
        },

        setValues() {
            this.values = [];
            console.log(this.values);
            this.selects.forEach((elem) => {
                const select = elem.querySelector('select');
                this.values.push(select.value);
            });
            this.getData();
        },

        getData() {
            console.log('using these values to fetch new data', this.values);
            console.log(this.values);

            const selectedFooter = document.getElementById(this.values[0]);
            const visibleFooter = document.querySelector('.active');
            const showSelected = selectedFooter && selectedFooter.offsetParent === null;

            if (visibleFooter && visibleFooter !== selectedFooter) {
                visibleFooter.classList.remove('active');
                visibleFooter.classList.add('hidden');
                console.log('AZ: hiding old footer');
            }

            if (showSelected) {
                console.log('AZ: showing selected footer');
                selectedFooter.classList.remove('hidden');
                selectedFooter.classList.add('active');
            }
        },
    },

    {
        init() {
            this.values = [];
            let selectNodeList = document.querySelectorAll('[data-filterblock-dropdown]');
            this.selects = selectNodeList;

            // set up select events
            this.initSelects();
        },
    },
);

export default dropDown;
