import {createBehavior} from '@area17/a17-behaviors';

const usccHeader = createBehavior('usccHeader',
    {
        bodyLocked() {
            this.lockedBody = true;
        },
        bodyUnlocked() {
            this.lockedBody = false;
        },
        reset() {
            document.dispatchEvent(new CustomEvent('header:top'));
            this.scrollY = 0;
            this.scrollChangedAt = 0;
            this.scrollDirection = 'down';
            this.$node.style.top = '0';
            this.$node.classList.remove('s-fixed');
            this.state = 'top';
        },
        unfix() {
            document.dispatchEvent(new CustomEvent('header:absolute'));
            this.$node.classList.remove('s-fixed');
            this.$node.style.top = `${this.scrollChangedAt + this.h}px`;
            this.state = 'absolute';
        },
        fix() {
            document.dispatchEvent(new CustomEvent('header:fixed'));
            this.$node.classList.add('s-fixed');
            this.state = 'fixed';
        },
        scrollWatcher() {
            const scrollY = window.pageYOffset;

            // if scroll position is the same, do nothing
            if (scrollY !== this.scrollY && !this.lockedBody) {
                if (scrollY < 1) {
                    // reset at the top
                    this.reset();
                } else {

                    const scrollDirection = (scrollY > this.scrollY) ? 'down' : 'up';

                    // looks like scroll position changed
                    if (this.scrollDirection !== scrollDirection) {
                        this.scrollChangedAt = scrollY;
                        this.scrollDirection = scrollDirection;

                        // if we're going up, set lock top value
                        // header is still position absolute
                        // so will end up scrolling back into view
                        if (scrollDirection === 'up') {
                            const lockTop = this.scrollChangedAt - this.h;
                            this.$node.style.top = `${lockTop > 0 ? lockTop : 0}px`;
                        }
                    }

                    // if we're going up and the header is fully in view
                    // fix its position so it doesn't keep sticking to the page scroll
                    if (this.state !== 'fixed' && scrollDirection === 'up' && this.scrollChangedAt - scrollY > this.h) {
                        this.fix();
                    }

                    // if we're going down and we've scrolled further than height of
                    // the header, unstick it
                    // using the header height to stop the hide/show feeling nervous
                    if (this.state !== 'absolute' && scrollDirection === 'down' && this.scrollChangedAt > 0 && scrollY - this.scrollChangedAt > this.h) {
                        this.unfix();
                    }

                    this.scrollY = scrollY;
                }
            }

            this.loop = window.requestAnimationFrame(this.scrollWatcher);
        },
    },
    {
        init() {
            this.scrollY = -1;
            this.h = this.$node.offsetHeight;
            this.scrollChangedAt = 0;
            this.scrollDirection = '';
            this.state = 'top';
            this.lockedBody = false;

            document.addEventListener('body:lock', this.bodyLocked, false);
            document.addEventListener('body:unlock', this.bodyUnlocked, false);
            document.addEventListener('usccHeader:fix', this.fix, false);
            document.addEventListener('usccHeader:reset', this.reset, false);
            this.loop = window.requestAnimationFrame(this.scrollWatcher);
        },
        resized() {
            this.h = this.$node.offsetHeight;
        },
        destroy() {
            document.removeEventListener('body:lock', this.bodyLocked);
            document.removeEventListener('body:unlock', this.bodyUnlocked);
            document.removeEventListener('usccHeader:fix', this.fix);
            document.removeEventListener('usccHeader:reset', this.reset);
            try {
                window.cancelAnimationFrame(this.loop);
            } catch (err) {
            }
        },
    },
);

export default usccHeader;

