import {createBehavior} from '@area17/a17-behaviors';
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

const slider = createBehavior(
    'slider',
    {
        initSlider() {
            const galleryElement = this.$node.querySelector('.swiper');
            const prevButton = this.$node.querySelector('.swiper-button-prev');
            const nextButton = this.$node.querySelector('.swiper-button-next');
            const config = {
                modules: [Navigation, Pagination],
                allowTouchMove: false,
                loop: true,
                observer: true,
                observeParents: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
            };
            this.swiper = new Swiper(galleryElement, config);
        },

        setSlideCentering() {
            this.slides = [...this.$node.querySelectorAll('.slide-content')];

            this.slides.forEach((elem) => {
                const height = elem.offsetHeight;
                const width = elem.offsetWidth;

                if (width > height) {
                    elem.classList.add('landscape');
                } else {
                    elem.classList.add('portrait');
                }
            });
        },

        switchDivsBehavior() {
            const specificDiv = document.querySelector('[data-component="block:galleryEmbed"]');
            if (specificDiv) {
                const ctaLinks = specificDiv.querySelectorAll('#ctaLink');

                if (ctaLinks) {
                    ctaLinks.forEach((ctaLink) => {
                        ctaLink.addEventListener('click', (event) => {
                            event.preventDefault();
                            const introDiv = document.getElementById('introDiv');
                            const galleryDiv = document.getElementById('galleryDiv');

                            if (introDiv && galleryDiv) {
                                introDiv.classList.add('hidden');
                                galleryDiv.classList.remove('hidden');
                            }
                        });
                    });
                }
            }
        },
    },

    {
        init() {
            this.initSlider();
            this.setSlideCentering();
            this.switchDivsBehavior();
        },
    },
);

export default slider;