const checkCmsPaths = function() {
    // check for CMS and redirect
    if (
        window.location.href === 'https://cms.uschamber.com/' ||
        window.location.href === 'https://cms.www.uschamber.com/'
    ) {
        window.location.pathname = '/cms';
    } else if (
        window.location.href === 'https://stg.cms.uschamber.com/' ||
        window.location.href === 'https://cms.staging.uschamber.com/'
    ) {
        window.location.pathname = '/cms';
    }
};

export default checkCmsPaths;
