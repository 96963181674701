import {createBehavior} from '@area17/a17-behaviors';

const tableOfContents = createBehavior('tableOfContents', {
        matchLink(h3Text, links) {
            for (let i = 0; i < links.length; i++) {
                const linkText = links[i].innerText.trim();
                const linkHref = links[i].getAttribute('href');
                if (h3Text === linkText) {
                    // remove the # from the href
                    return linkHref.substring(1);
                }
            }
            return null;
        },
        addHeaderReset() {
            document.dispatchEvent(new CustomEvent('usccHeader:reset'));
        },
        createBackLink(toc) {
            const url = `#${toc.id}`;
            const backLink = document.createElement('a');
            backLink.classList.add('f-ui-3', 'link-underline', 'hover:link-underline-off', 'hover:text-link-off');
            backLink.dataset.component = 'atom:link';
            backLink.href = url;
            backLink.innerHTML = 'Back to top';
            backLink.addEventListener('click', () => {
                this.addHeaderReset();
            });
            return backLink;
        },
    },
    {
        init() {
            const articleBody = document.querySelector('.article-body .article-body-center.blocks');

            const toc = document.querySelector('[data-behavior="tableOfContents"]');
            const tocLinks = toc.querySelectorAll('ul > li > a');

            const h2s = document.querySelectorAll('.article-body .blocks > h2');

            const titleBarH2s = document.querySelectorAll('[data-component="block:title-bar"]  h2');
            // add one to the end, but before the author block if exists
            const authorBlock = document.querySelector('[data-component="block:author-bio"]');

            for (let i = 0; i < h2s.length; i++) {
                const matchingLink = this.matchLink(h2s[i].innerText.trim(), tocLinks);
                if (matchingLink) {
                    h2s[i].id = matchingLink;
                }
            }

            for (let i = 0; i < titleBarH2s.length; i++) {
                const matchingLink = this.matchLink(titleBarH2s[i].innerText.trim(), tocLinks);
                if (matchingLink) {
                    titleBarH2s[i].id = matchingLink;
                }
            }
            for (let i = 0; i < tocLinks.length; i++) {
                tocLinks[i].addEventListener('click', () => {
                    this.addHeaderReset();
                });
            }
            for (let i = 2; i < h2s.length; i++) {
                const backLink = this.createBackLink(toc);
                h2s[i].parentNode.insertBefore(backLink, h2s[i]);
            }

            if (authorBlock) {
                authorBlock.parentNode.insertBefore(this.createBackLink(toc), authorBlock);
            } else if (articleBody) {
                articleBody.appendChild(this.createBackLink(toc));
            }
        },
    });

export default tableOfContents;
