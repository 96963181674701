import {createBehavior} from '@area17/a17-behaviors';

/** YouTube player states
 -1 (unstarted)
 0 (ended)
 1 (playing)
 2 (paused)
 3 (buffering)
 5 (video cued)
 **/

const videoPlaylist = createBehavior(
    'videoPlaylist',
    {
        resetHeight(ev) {
            this.setHeight(ev.detail.height);
        },
        setHeight(height) {
            const currentBreakpoint = getComputedStyle(document.documentElement)
                .getPropertyValue('--breakpoint')
                .replace(/['"]+/g, '')
                .trim();
            const parentHero = this.options.parent;
            const allowedBreakpoints = ['xl', 'xxl', 'xxxl', 'xxxxl'];
            if (allowedBreakpoints.includes(currentBreakpoint) && parentHero == 'video-default') {
                this.$node.style.minHeight = height + 'px';
            } else {
                this.$node.style.minHeight = '';
            }
        },
    },
    {
        init() {
            const playlist = document.querySelector('.playlist');
            const currentVideo = document.querySelector('.current-video');
            if (playlist && currentVideo) {
                playlist.scrollLeft = currentVideo.offsetLeft - playlist.offsetLeft - 10;
            }
            // get the video player if it exists
            const $videoPlayer = document.querySelector(
                '[data-component="atom:video"]',
            );
            if ($videoPlayer) {
                this.setHeight($videoPlayer.offsetHeight);
            }
            document.addEventListener(
                'videoPlaylist:resetHeight',
                this.resetHeight,
                false,
            );
        },
        destroy() {
            document.removeEventListener(
                'videoPlaylist:resetHeight',
                this.resetHeight,
            );
        },
    },
);

export default videoPlaylist;
