import {createBehavior} from '@area17/a17-behaviors';

const openModal = createBehavior(
    'openModal',
    {
        click(e) {
            e.preventDefault();
            e.stopPropagation();
            const $modal = document.getElementById(this.options.id);
            if ($modal) {
                $modal.dispatchEvent(new CustomEvent('modal:open', {
                    detail: {
                        opener: this.$node,
                    },
                }));
            } else {
                console.log('can\'t find modal:', this.options.id);
            }
        },
    },
    {
        init() {
            this.$node.addEventListener('click', this.click, false);
        },
        destroy() {
            this.$node.removeEventListener('click', this.click);
        },
    },
);

export default openModal;

