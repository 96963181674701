export function lockBody() {
    let lockBodyScrollTop = 0;
    let locked = false;

    function _lock() {
        locked = true;
        lockBodyScrollTop = 0;
        lockBodyScrollTop = window.pageYOffset;

        // if a captcha is contained in the modal we need to reset it's position
        if (!document.querySelector('.g-recaptcha')) {
            document.body.style.top = (lockBodyScrollTop * -1) + 'px';
            document.documentElement.classList.add('s-body-locked');
        }

    }

    function _unlock() {
        if (locked) {
            locked = false;
            document.body.style.top = '';
            document.documentElement.classList.remove('s-body-locked');
            window.scrollTo(0, lockBodyScrollTop);
        }
    }

    document.addEventListener('body:lock', _lock, false);
    document.addEventListener('body:unlock', _unlock, false);
}

export default lockBody;
