import {createBehavior} from '@area17/a17-behaviors';
import $ from 'jquery';
import 'datatables.net';

const dataTable = createBehavior(
    'dataTable',
    {
        init() {
            window.$ = $;

            let dataTable = $('#' + this.$node.dataset.dataTableId);
            let dataTableWrapper = dataTable.parent();

            let pageLength = parseInt(this.$node.dataset.pageLength);
            let rowCount = parseInt(this.$node.dataset.rowCount) ?? 0;
            let displayPaging = rowCount >= pageLength ? 1 : 0;
            let displaySearchBox = parseInt(this.$node.dataset.displaySearch);

            dataTable.DataTable({
                'paging': displayPaging,
                'pageLength': pageLength,
                'lengthChange': 0,
                'ordering': 1,
                'info': 0,
                'searching': displaySearchBox,
                'scrollX': true,
                'scrollY': true,
                'scrollCollapse': true,
                'initComplete': function() {
                    dataTableWrapper.css('opacity', '1');
                },
            });
        },
        destroy() {
            let dataTable = $('#' + this.$node.dataset.dataTableId);
            dataTable.destroy();
        },
    },
);

export default dataTable;
