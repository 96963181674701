import {createBehavior} from '@area17/a17-behaviors';

/*
TODO:

- find the double click bug

*/

const menu = createBehavior('menu',
    {
        focusOnFirstEl($target) {
            const $focussableEl = $target.querySelector('a:not([aria-hidden="true"]), button:not([aria-hidden="true"]):not([data-menu-dropdownmask])');
            if ($focussableEl) {
                $focussableEl.focus();
            } else {
                $target.setAttribute('tabindex', '-1');
                $target.focus();
                $target.removeAttribute('tabindex');
            }
        },
        headerClick() {
            this.closeMenu();
            this.closeDropdowns(true);
        },
        escapePressed(e) {
            var isEscape = false;
            if ('key' in e) {
                isEscape = (e.key === 'Escape' || e.key === 'Esc');
            } else {
                isEscape = (e.keyCode === 27);
            }
            if (isEscape) {
                e.preventDefault();
                this.closeMenu();
                this.closeDropdowns(true);
            }
        },
        maskClick() {
            this.closeMenu();
            this.closeDropdowns(true);
        },
        menuTriggerBtnClick(e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.menuOpen) {
                this.closeMenu();
            } else {
                this.openMenu();
            }
        },
        closeMenuClick(e) {
            e.preventDefault();
            this.closeMenu();
        },
        menuForwardsBtnClick(e) {
            e.preventDefault();
            this.goForwards(e.target);
        },
        menuBackBtnClick(e) {
            e.preventDefault();
            this.goBack();
        },
        dropdownTriggerBtnClick(e) {
            e.preventDefault();
            e.stopPropagation();

            const i = Array.prototype.indexOf.call(this.$dropdownTriggerBtns, e.target);
            this.toggleDropdown(i, e.target);
        },
        dropdownCloseBtnClick() {
            this.closeDropdowns(true);
        },
        openMenu() {
            if (this.menuOpen) {
                return;
            }
            document.dispatchEvent(new CustomEvent('modal:close:all'));
            this.menuOpen = true;
            this.closeDropdowns(true);

            let menuList = this.$node.querySelector(`.menu-list`);
            menuList.removeAttribute('aria-hidden');

            this.hideMainElement();

            let parentMenuAttribute;


            // The Menu on large breakpoints has the main menu hidden and shows the More from Us Chamber Sub List
            if (this.isBreakpoint('lg+')) {
                parentMenuAttribute = this.secondaryList;
            } else {
                parentMenuAttribute = this.initalList;
            }

            // make links tabbable
            this.activateLinks(
                this.$node.querySelectorAll(`a[data-menu-parent="${parentMenuAttribute}"], button[data-menu-parent="${parentMenuAttribute}"]`),
            );
            // lock the focus inside the menu when tabbing
            document.dispatchEvent(new CustomEvent('body:lock', {
                detail: {
                    element: this.$node,
                },
            }));
            document.dispatchEvent(new CustomEvent('focus:trap', {
                detail: {
                    element: this.$node,
                },
            }));
            // show the nav
            this.$documentElement.classList.add('s-menu-active');
            // focus on the first link
            this.focusOnFirstEl(this.$closeMenuBtn);
        },
        closeMenu() {
            if (!this.menuOpen) {
                return;
            }
            // hide the menu
            this.$documentElement.classList.remove('s-menu-active');
            // untrap focus
            document.dispatchEvent(new CustomEvent('focus:untrap'));
            document.dispatchEvent(new CustomEvent('body:unlock'));
            // focus on the menu open btn so user returns to where they started in the nav
            this.$menuTriggerBtn.focus();
            // reset the menu to the initial state
            setTimeout(() => {
                this.resetMenu();
            }, this.listSlideTime);
            // stop links being tabbable

            let menuList = this.$node.querySelector(`.menu-list`);
            menuList.setAttribute('aria-hidden', true);

            this.showMainElement();


            let parentMenuAttribute;
            // The Menu on large breakpoints has the main menu hidden and shows the More from Us Chamber Sub List
            if (this.isBreakpoint('lg+')) {
                parentMenuAttribute = this.secondaryList;
            } else {
                parentMenuAttribute = this.initalList;
            }
            this.deactivateLinks(
                this.$node.querySelectorAll(`a[data-menu-parent="${parentMenuAttribute}"], button[data-menu-parent="${parentMenuAttribute}"]`),
            );
            //
            this.menuOpen = false;
        },
        goForwards(el, reset) {
            // grab names of parent and next lists from DOM
            const data = el.dataset.menuForward.split('::');
            const $parentList = this.$node.querySelector(`[data-menu-items="${data[0]}"]`);
            const $nextList = this.$node.querySelector(`[data-menu-items="${data[1]}"]`);
            // toggle classes to show the selected list
            $parentList.classList.remove('s-current');
            $parentList.classList.add('s-previous');
            $nextList.classList.add('s-current');
            setTimeout(() => {
                $parentList.firstElementChild.style.transition = 'none';
                window.requestAnimationFrame(() => {
                    $parentList.classList.add('s-previous-fixed');
                });
            }, reset ? 0 : this.listSlideTime);
            // set aria hidden
            $parentList.setAttribute('aria-hidden', true);
            $nextList.removeAttribute('aria-hidden');
            // toggle ability to tab links
            this.activateLinks(
                this.$node.querySelectorAll(`a[data-menu-parent="${data[1]}"], button[data-menu-parent="${data[1]}"]`),
            );
            this.deactivateLinks(
                this.$node.querySelectorAll(`a[data-menu-parent="${data[0]}"], button[data-menu-parent="${data[0]}"]`),
            );
            // focus on the first link in the next list (the back button)
            if (!reset) {
                setTimeout(() => {
                    this.$node.querySelector(`a[data-menu-parent="${data[1]}"], button[data-menu-parent="${data[1]}"]`).focus();
                }, this.listSlideTime + 32);
            }
            // add to internal breadcrumb
            this.breadcrumb.push(data[1]);
        },
        goBack() {
            // work out what the active list and parent lists are from breadcrumn
            const parentList = this.breadcrumb[this.breadcrumb.length - 2];
            const thisList = this.breadcrumb[this.breadcrumb.length - 1];
            const $parentList = this.$node.querySelector(`[data-menu-items="${parentList}"]`);
            const $thisList = this.$node.querySelector(`[data-menu-items="${thisList}"]`);
            // toggle ability to tab links
            this.activateLinks(
                this.$node.querySelectorAll(`a[data-menu-parent="${parentList}"], button[data-menu-parent="${parentList}"]`),
            );
            this.deactivateLinks(
                this.$node.querySelectorAll(`a[data-menu-parent="${thisList}"], button[data-menu-parent="${thisList}"]`),
            );
            //
            // toggle classes to hide the current list
            $parentList.classList.remove('s-previous-fixed');
            window.requestAnimationFrame(() => {
                $parentList.firstElementChild.style.transition = '';
                window.requestAnimationFrame(() => {
                    $parentList.classList.add('s-current');
                    $parentList.classList.remove('s-previous');
                    $thisList.classList.remove('s-current');
                });
            });
            // set aria hidden
            $thisList.setAttribute('aria-hidden', true);
            $parentList.removeAttribute('aria-hidden');
            // focus on close button, maybe the user is trying to get out
            this.$closeMenuBtn.focus();
            // update breadcrumb
            this.breadcrumb.pop();
        },
        resetMenu() {
            this.$node.querySelectorAll('.s-current, .s-previous, .s-previous-fixed').forEach($el => {
                $el.firstElementChild.style.transition = '';
                $el.classList.remove('s-current', 's-previous', 's-previous-fixed');
            });
            this.breadcrumb = [this.initalList];
            // if large, jump to the first none drop down section (More from chamber)
            const $jumpTo = this.$node.querySelector('[data-menu-hasdropdown="false"]');
            if (this.isBreakpoint('lg+') && $jumpTo) {
                this.goForwards($jumpTo, true);
            } else {
                this.$node.querySelector('[data-menu-items="main"]').classList.add('s-current');
            }
        },
        activateLinks($nodelist) {
            // loop and remove tabindex and aria hidden
            $nodelist.forEach($el => {
                $el.removeAttribute('tabindex');
                $el.removeAttribute('aria-hidden');
            });
        },
        deactivateLinks($nodelist) {
            // loop and add tabindex and aria hidden
            $nodelist.forEach($el => {
                $el.setAttribute('tabindex', '-1');
                $el.setAttribute('aria-hidden', true);
            });
        },
        closeDropdowns(reset) {
            if (this.dropdownOpen === -1) {
                return;
            }
            // close
            this.$dropdowns[this.dropdownOpen].classList.remove('s-current');
            this.$dropdownTriggerBtns[this.dropdownOpen].parentNode.classList.remove('s-active');
            // untrap focus
            document.dispatchEvent(new CustomEvent('focus:untrap'));
            //
            this.deactivateLinks(
                this.$dropdowns[this.dropdownOpen].querySelectorAll(`a, button`),
            );
            // reset open value
            if (reset) {
                this.$documentElement.classList.remove('s-menu-dropdown-active');
                document.dispatchEvent(new CustomEvent('body:unlock'));
                setTimeout(() => {
                }, !this.menuOpen ? this.menuRevealTime : 0);
                // focus back on the btn that opened the drop down
                this.$dropdownTriggerBtns[this.dropdownOpen].focus();
                this.dropdownOpen = -1;
            }
        },
        toggleDropdown(i, $trigger) {
            if (this.dropdownOpen !== i) {
                this.closeDropdowns();
                document.dispatchEvent(new CustomEvent('body:lock'));
                this.$documentElement.classList.add('s-menu-dropdown-active');
                this.$dropdowns[i].classList.add('s-current');
                this.$dropdowns[i].setAttribute('aria-hidden', false);
                this.$dropdowns[i].style.left = `${$trigger.offsetLeft}px`;
                this.$dropdowns[i].querySelector('.menu-dropdown-container').style.left = `${$trigger.offsetLeft}px`;
                this.$dropdownTriggerBtns[i].parentNode.classList.add('s-active');
                this.dropdownOpen = i;
                // make links tabbable
                this.activateLinks(
                    this.$dropdowns[i].querySelectorAll(`a, button`),
                );
                // lock the focus inside the active drop down
                document.dispatchEvent(new CustomEvent('focus:trap', {
                    detail: {
                        element: this.$dropdowns[i],
                    },
                }));
                // focus on the first link
                this.focusOnFirstEl(this.$dropdowns[i]);
                //
                this.dropdownOpen = i;
                this.hideMainElement();
            } else {
                this.closeDropdowns(true);
                this.showMainElement();
                this.$dropdowns[i].setAttribute('aria-hidden', true);
            }
        },
        hideMainElement() {
            // add aria hidden to main element when menu is overlaying rest of page
            let mainElement = this.$documentElement.querySelector(`#main`);
            mainElement.setAttribute('aria-hidden', true);
        },

        showMainElement() {
            // remove aria hidden from main element when menu is overlaying rest of page
            let mainElement = this.$documentElement.querySelector(`#main`);
            mainElement.removeAttribute('aria-hidden');
        },
    },
    {
        init() {
            this.menuOpen = false;
            this.dropdownOpen = -1;
            this.level = 0;
            this.listSlideTime = parseInt(getComputedStyle(this.$node).getPropertyValue('--listSlideTime'), 10) || 333;
            this.menuRevealTime = parseInt(getComputedStyle(this.$node).getPropertyValue('--menuRevealTime'), 10) || 333;
            this.initalList = 'main';
            this.secondaryList = 'more-from-the-chamber';
            this.breadcrumb = [this.initalList];

            this.$documentElement = document.documentElement;
            this.$header = this.getChild('header', document);
            this.$menuTriggerBtn = this.getChild('trigger', document);
            this.$closeMenuBtn = this.getChild('close');
            this.$menuForwardsBtns = this.getChildren('forward');
            this.$menuBackBtns = this.getChildren('back');
            this.$dropdownTriggerBtns = this.getChildren('dropdown-trigger', document);
            this.$dropdowns = this.getChildren('dropdown');
            this.$dropdownCloseBtns = this.getChildren('dropdown-close');

            document.addEventListener('mask:clicked', this.maskClick, false);
            document.addEventListener('menu:close:all', this.maskClick, false);

            this.$menuTriggerBtn.addEventListener('click', this.menuTriggerBtnClick, false);
            this.$closeMenuBtn.addEventListener('click', this.closeMenuClick, false);

            this.$menuForwardsBtns.forEach($btn => {
                $btn.addEventListener('click', this.menuForwardsBtnClick, false);
            });
            this.$menuBackBtns.forEach($btn => {
                $btn.addEventListener('click', this.menuBackBtnClick, false);
            });

            this.$dropdownTriggerBtns.forEach($btn => {
                $btn.addEventListener('click', this.dropdownTriggerBtnClick, false);
            });
            this.$dropdownCloseBtns.forEach($btn => {
                $btn.addEventListener('click', this.dropdownCloseBtnClick, false);
            });

            document.addEventListener('keydown', this.escapePressed, false);

            this.resetMenu();
        },
        mediaQueryUpdated(e) {
            const currBp = e.detail.breakpoint;
            const prevBp = e.detail.prevBreakpoint;
            const bps = this.__breakpoints;
            const lgI = this.__breakpoints.indexOf('lg');
            const currI = this.__breakpoints.indexOf(currBp);
            const prevI = this.__breakpoints.indexOf(prevBp);
            let resetMenu = false;

            if (prevI < lgI && currI >= lgI) {
                resetMenu = true;
            } else if (prevI >= lgI && currI < lgI) {
                // gone from lg+ to md-
                resetMenu = true;
            }

            if (resetMenu) {
                if (this.menuOpen) {
                    this.$node.classList.add('hidden');
                    this.closeMenu();
                    setTimeout(() => {
                        this.$node.classList.remove('hidden');
                    }, this.listSlideTime);
                }
                this.closeDropdowns(true);
                this.resetMenu();
            }
        },
        destroy() {
            document.removeEventListener('mask:clicked', this.maskClick);
            document.removeEventListener('menu:close:all', this.maskClick);
            this.$header.removeEventListener('click', this.headerClick);

            this.$menuTriggerBtn.removeEventListener('click', this.menuTriggerBtnClick);
            this.$closeMenuBtn.removeEventListener('click', this.closeMenuClick);

            this.$menuForwardsBtns.forEach($btn => {
                $btn.removeEventListener('click', this.menuForwardsBtnClick);
            });
            this.$menuBackBtns.forEach($btn => {
                $btn.removeEventListener('click', this.menuBackBtnClick);
            });

            this.$dropdownTriggerBtns.forEach($btn => {
                $btn.removeEventListener('click', this.dropdownTriggerBtnClick);
            });
            this.$dropdownCloseBtns.forEach($btn => {
                $btn.removeEventListener('click', this.dropdownCloseBtnClick);
            });

            document.removeEventListener('keydown', this.escapePressed);
        },
    },
);

export default menu;

