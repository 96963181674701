const scrollToFeed = function() {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = window.location.hash;
    const onRiverPage = urlParams.get('page');
    if (hash && onRiverPage) {
        document.getElementById(hash.substring(1)).scrollIntoView();
    }
};

export default scrollToFeed;
