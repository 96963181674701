import {createBehavior} from '@area17/a17-behaviors';

const accordion = createBehavior(
    'accordion',
    {
        triggerClick(e) {
            e.preventDefault();
            const $trigger = e.currentTarget;
            const $content = $trigger.nextElementSibling;
            const $wrapper = $content.firstElementChild;
            const $icon = $trigger.querySelector('[data-icon]');
            const open = $trigger.getAttribute('aria-expanded') === 'true';

            if (open) {
                $content.style.height = $content.offsetHeight + 'px';
                const thrash = $content.offsetHeight;
                $content.classList.remove('duration-200', 'ease-in-1', 'opacity-100');
                $content.classList.add('duration-150', 'ease-out-1');
                $content.style.height = '0px';
                $trigger.setAttribute('aria-expanded', false);
                $trigger.classList.remove('bg-accent-secondary', 'text-accent-primary');
                $icon.classList.remove('rotate-180');
                setTimeout(() => {
                    $content.classList.add('hidden');
                }, 150);
            } else {
                $content.style.height = '0px';
                const thrash = $content.offsetHeight;
                $content.classList.remove('hidden', 'duration-150', 'ease-out-1');
                $content.classList.add('duration-200', 'ease-in-1');
                $content.style.height = $wrapper.offsetHeight + 'px';
                $content.classList.add('opacity-100');
                $trigger.setAttribute('aria-expanded', true);
                $trigger.classList.add('bg-accent-secondary', 'text-accent-primary');
                $icon.classList.add('rotate-180');
            }
        },
    },
    {
        init() {
            this.rotateClass = 'rotate-180';
            this.$triggers = this.getChildren('trigger');

            this.$triggers.forEach($trigger => {
                $trigger.addEventListener('click', this.triggerClick, false);
            });
        },
        destroy() {
            this.$triggers.forEach($trigger => {
                $trigger.removeEventListener('click', this.triggerClick, false);
            });
        },
    },
);

export default accordion;