import {createBehavior} from '@area17/a17-behaviors';

const activeTabClasses = [
    `f-heading-1`,
    `border-tab-active`,
    `pointer-events-none`,
];
const inactiveTabClasses = [
    `f-ui-3`,
    `border-b-transparent`,
    `hover:border-tab-on`,
];

const activeSectionClasses = ['un-hidden'];
const inactiveSectionClasses = ['hidden'];

const tabs = createBehavior(
    'tabs',
    {
        getUrlParam() {
            const searchParams = new URLSearchParams(window.location.search);
            return searchParams.get('tab');
        },
        setUrlParam(index) {
            const searchParams = new URLSearchParams();
            searchParams.set('tab', index);
            window.history.pushState(null, '', `?${searchParams.toString()}`);
        },
        setTab(pulledFromUrlParam) {
            this.tabs.forEach((element, index) => {
                if (this.currentTabIndex === index) {
                    element.classList.add(...activeTabClasses);
                    element.classList.remove(...inactiveTabClasses);
                    if (!pulledFromUrlParam) {
                        this.setUrlParam(index);
                    }
                } else {
                    element.classList.add(...inactiveTabClasses);
                    element.classList.remove(...activeTabClasses);
                }
            });
            this.setCurrentSection();
        },

        initTabs() {
            this.tabs.forEach((element, index) => {
                element.addEventListener('click', (e) => {
                    e.preventDefault();
                    this.currentTabIndex = index;
                    this.setTab();
                });
            });
        },

        setCurrentTab() {
            this.tabs.forEach((elem, index) => {
                if (elem.classList.contains('pointer-events-none')) {
                    return index;
                }
            });
        },

        setCurrentSection() {
            if (this.sections.length > 0) {
                this.sections.forEach((section, index) => {
                    if (this.currentTabIndex === index) {
                        document.dispatchEvent(new CustomEvent('tab:change', {detail: {index}}));
                        section.classList.add(...activeSectionClasses);
                        section.classList.remove(...inactiveSectionClasses);
                    } else {
                        section.classList.add(...inactiveSectionClasses);
                        section.classList.remove(...activeSectionClasses);
                    }
                });
                this.triggerWindowUpdate();
            }
        },

        triggerWindowUpdate() {
            const event = new Event('tab-update');
            window.dispatchEvent(event);
        },

        setOverflow() {
            let tabWidth = 0;
            this.tabs.forEach((elem) => {
                tabWidth = tabWidth + elem.offsetWidth;
            });
            const containerWidth =
                document.querySelector('.container').offsetWidth;
            if (containerWidth < tabWidth) {
                this.scroller.classList.remove('w-full');
            } else {
                this.scroller.classList.add('w-full');
            }
        },
    },

    {
        init() {

            this.scroller = this.getChild('scroller');
            this.tabs = [...this.getChildren('tab')];
            this.sections = [...this.getChildren('section')];
            this.currentTabIndex = this.setCurrentTab();
            this.setOverflow();

            window.addEventListener('resize', () => {
                this.setOverflow();
            });

            if (this.getUrlParam()) {
                this.currentTabIndex = parseInt(this.getUrlParam());
                this.setTab(true);
            }

            this.initTabs();
        },
    },
);

export default tabs;
