import {createBehavior} from '@area17/a17-behaviors';
import {escapeString} from '@area17/a17-helpers';
import copyTextToClipboard from '../functions/copyTextToClipboard.js';

const share = createBehavior(
    'share',
    {
        pageUrl() {
            return escapeString(this.shareUrl);
        },
        pageTitle() {
            return escapeString(this.pageTitle);
        },
        showCopySuccessFailureMsg(msg) {
            clearTimeout(this.msgTimer);
            if (msg === 'success') {
                this.$msg.textContent = 'Link copied to clipboard';
            } else {
                console.log('copy failed: ', msg);
                this.$msg.textContent =
                    'Sorry, your browser does not support copying to the clipboard';
            }
            this.$msg.setAttribute('aria-hidden', false);
            this.$msg.classList.remove('opacity-0');
            this.$msg.classList.add('max-h-60');
            this.msgTimer = setTimeout(() => {
                this.$msg.setAttribute('aria-hidden', true);
                this.$msg.classList.add('opacity-0');
                this.$msg.classList.remove('max-h-60');
                setTimeout(() => {
                    this.$msg.textContent = '';
                }, 200);
            }, 2000);
        },
        copyLink() {
            copyTextToClipboard(this.shareUrl)
                .then((copyResult) => {
                    this.showCopySuccessFailureMsg(copyResult.msg);
                })
                .catch((copyResult) => {
                    this.showCopySuccessFailureMsg(copyResult.msg);
                });
        },
        openWindow(href, options) {
            if (!options) {
                options = {};
            }

            let width = options.width || 575;
            let height = options.height || 400;
            let left = (window.outerWidth - width) / 2;
            let top = (window.outerHeight - height) / 2;
            let opts =
                'status=1' +
                ',width=' +
                width +
                ',height=' +
                height +
                ',top=' +
                top +
                ',left=' +
                left;

            if (this.socialWindowRef && !this.socialWindowRef.closed) {
                this.socialWindowRef.close();
            }

            setTimeout(() => {
                this.socialWindowRef = window.open(href, 'AIC_Share', opts);
                this.socialWindowRef.opener = null;
            }, 50);
        },
        populateLinks() {
            this.getChildren('network').forEach(($action) => {
                const network = $action.dataset.shareNetwork;
                if (this.networks[network]) {
                    $action.href = this.networks[network].href;
                }
            });
        },
        handleClicks(event) {
            event.preventDefault();
            event.stopPropagation();
            event.target.blur();
            const network = event.target.dataset.shareNetwork;
            if (network === 'copy') {
                this.copyLink();
            } else if (this.networks[network]) {
                this.openWindow(
                    event.target.href,
                    this.networks[network].windowOptions,
                );
            }
        },
    },
    {
        init() {
            this.shareUrl = this.options.url || window.location.href;
            this.shareTitle = document.title;
            this.$msg = this.getChild('msg');
            this.msgTimer;

            this.networks = {
                facebook: {
                    href: `https://www.facebook.com/sharer/sharer.php?u=${this.pageUrl()}`,
                    windowOptions: {},
                },
                twitter: {
                    href: `https://twitter.com/intent/tweet?url=${this.pageUrl()}&text=${this.pageTitle()}${
                        this.options.twitterHandle
                            ? '&via=' + this.options.twitterHandle
                            : ''
                    }`,
                    windowOptions: {
                        height: 253,
                    },
                },
                linkedin: {
                    href: `https://www.linkedin.com/cws/share?url=${this.pageUrl()}`,
                    windowOptions: {
                        width: 600,
                        height: 370,
                    },
                },
                email: {
                    href: `mailto:?Subject=${this.pageTitle()}&Body=${this.pageTitle()}\n\n${this.pageUrl()}\n\n`,
                    windowOptions: {},
                },
            };

            this.populateLinks();
            this.$node.addEventListener('click', this.handleClicks, false);
        },
        destroy() {
            this.$node.removeEventListener('click', this.handleClicks);
        },
    },
);

export default share;
