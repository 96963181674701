import {createBehavior} from '@area17/a17-behaviors';

const mask = createBehavior('mask',
    {
        click() {
            document.dispatchEvent(new CustomEvent('mask:clicked'));
        },
    },
    {
        init() {
            this.$node.addEventListener('click', this.click, false);
        },
        destroy() {
            this.$node.removeEventListener('click', this.click);
        },
    },
);

export default mask;

