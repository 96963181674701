import {createBehavior} from '@area17/a17-behaviors';

const notificationCta = createBehavior(
    'notificationCta',
    {
        show() {
            this.$node.classList.remove('opacity-0', 'translate-y-20');
        },
        hide() {
            this.$node.classList.remove('ease-in-out-1', 'duration-200');
            this.$node.classList.add('ease-out-1', 'duration-150', 'opacity-0', 'translate-y-20');
            setTimeout(() => {
                this.$node.remove();
            }, 150);
        },
        click(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.tagName === 'BUTTON') {
                document.dispatchEvent(new CustomEvent('notificationCta:click', {
                    detail: {
                        element: e.target,
                        dataset: e.target.dataset,
                    },
                }));
                setTimeout(() => {
                    this.hide();
                }, 250);
            }
        },
    },
    {
        init() {
            this.$node.addEventListener('click', this.click, false);
            this.show();
        },
        destroy() {
            this.$node.removeEventListener('click', this.click);
        },
    },
);

export default notificationCta;

