import {createBehavior} from '@area17/a17-behaviors';

const modal = createBehavior('modal',
    {
        closeModal() {
            if (this.open) {
                document.dispatchEvent(new CustomEvent('body:unlock'));
                document.documentElement.classList.remove('s-modal-active');
                this.$node.classList.remove('s-active');
                document.dispatchEvent(new CustomEvent('focus:untrap'));
                if (this.opener) {
                    this.opener.focus();
                }
                setTimeout(() => {
                    this.$scrollarea.scrollTo(0, 0);
                }, this.revealTime);
                this.open = false;
            }
        },
        openModal(e) {
            if (!this.open) {
                document.dispatchEvent(new CustomEvent('menu:close:all'));
                document.dispatchEvent(new CustomEvent('body:lock', {
                    detail: {
                        element: this.$scrollarea,
                    },
                }));
                this.$node.classList.add('s-active');
                document.documentElement.classList.add('s-modal-active');
                this.opener = e.detail.opener ? e.detail.opener : null;
                document.dispatchEvent(new CustomEvent('focus:trap', {
                    detail: {
                        element: this.$node,
                    },
                }));
                this.$closeBtn.focus();
                this.open = true;
            }
        },
        maskClicked() {
            this.closeModal();
        },
        keyup(evt) {
            switch (evt.keyCode) {
                case 27:
                    this.closeModal();
                    break;
            }
        },
    },
    {
        init() {
            if (this.options.demo) {
                document.documentElement.classList.add('s-modal-active');
            } else {
                this.open = false;
                this.opener = null;
                this.$closeBtn = this.getChild('close');
                this.$scrollarea = this.getChild('scrollarea');
                this.revealTime = parseInt(getComputedStyle(this.$node).getPropertyValue('--modalRevealTime'), 10) || 500;

                document.addEventListener('mask:clicked', this.maskClicked, false);
                document.addEventListener('keyup', this.keyup, false);
                document.addEventListener('modal:close:all', this.closeModal, false);
                this.$node.addEventListener('modal:open', this.openModal, false);
                this.$closeBtn.addEventListener('click', this.closeModal, false);
            }
        },
        destroy() {
            if (!this.options.demo) {
                document.removeEventListener('mask:clicked', this.maskClicked);
                document.removeEventListener('keyup', this.keyup);
                document.removeEventListener('modal:close:all', this.closeModal);
                this.$node.removeEventListener('modal:open', this.openModal);
                this.$closeBtn.addEventListener('click', this.closeModal);
            }
        },
    },
);

export default modal;

