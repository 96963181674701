const ajax = (url, method = 'GET', body = null, type = 'xml') => {
    return new Promise((resolve, reject) => {
        var httpRequest = new XMLHttpRequest();
        if (!httpRequest) {
            alert('An unexpected error has occurred');
            reject(new Error('An unexpected error has occurred'));
        }
        httpRequest.onreadystatechange = function() {
            if (httpRequest.readyState === XMLHttpRequest.DONE) {
                if (httpRequest.status === 200) {
                    resolve(httpRequest.responseText);
                } else {
                    reject(new Error('There was a problem with the request.'));
                }
            }
        };

        httpRequest.open(method, url);
        if (type === 'xml') {
            httpRequest.setRequestHeader(
                'Content-Type',
                'application/x-www-form-urlencoded',
            );
        } else if (type === 'json') {
            httpRequest.setRequestHeader('Content-Type', 'application/json');
        }
        httpRequest.send(body);
    });
};

export default ajax;
