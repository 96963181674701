import {createBehavior} from '@area17/a17-behaviors';

const article = createBehavior(
    'article',
    {
        getCurrentBreakpoint() {
            return getComputedStyle(document.documentElement)
                .getPropertyValue('--breakpoint')
                .replace(/['"]+/g, '')
                .trim();
        },

        getElementHeight(el) {
            if (!el) {
                return null;
            }
            return el.clientHeight;
        },

        updateContentHeight() {
            this.currentBreakpoint = this.getCurrentBreakpoint();
            const disallowedBreakpoints = ['sm', 'md', 'lg'];

            if (disallowedBreakpoints.includes(this.currentBreakpoint)) {
                this.$node.querySelector('.article-body-center').style.minHeight = '';
                return;
            }
            const elements = {
                left: this.$node.querySelector('.article-body-left'),
                center: this.$node.querySelector('.article-body-center'),
                right: this.$node.querySelector('.article-body-right'),
            };
            let largestHeight = 0;
            for (const element in elements) {
                let height = this.getElementHeight(elements[element]);
                if (height > largestHeight) {
                    largestHeight = height;
                }
            }
            this.$node.querySelector('.article-body-center').style.minHeight =
                largestHeight + 'px';
        },
    },
    {
        init() {
            this.updateContentHeight();
            window.addEventListener('resize', this.updateContentHeight);
        },
        destroy() {
            this.$node.removeEventListener('click', this.click);
        },
    },
);

export default article;
