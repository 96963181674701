var copyTextToClipboard = function(textToCopy) {
    let success = false;
    let msg = 'error';

    if (navigator.clipboard && 'Promise' in window) {
        return new Promise((resolve, reject) => {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    resolve({success: true, msg: 'success'});
                })
                .catch(() => {
                    reject({success: false, msg: 'fail'});
                });
        });
    } else {
        console.log('we fell tyo here');
        var textArea = document.createElement('textarea');

        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        //textArea.value = textToCopy;
        textArea.textContent = textToCopy;
        document.body.appendChild(textArea);

        var selection = document.getSelection();
        var range = document.createRange();
        range.selectNode(textArea);
        selection.removeAllRanges();
        selection.addRange(range);

        try {
            var successful = document.execCommand('copy');
            if (successful) {
                success = true;
                msg = 'success';
            } else {
                msg = 'unknown execCommand error';
            }
        } catch (err) {
            msg = err;
        }

        document.body.removeChild(textArea);
    }

    // return {
    //     success: success,
    //     msg: msg,
    // }
};

export default copyTextToClipboard;
