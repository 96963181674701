import {createBehavior} from '@area17/a17-behaviors';
import * as d3 from 'd3';

const map = createBehavior(
    'map',
    {
        addEventListeners(mapId) {
            window.addEventListener(
                'resize',
                function (event) {
                    const page = window[mapId].page;
                    if (page.width !== window.innerWidth) {
                        page.width = window.innerWidth;
                        page.resize.rtime = new Date();
                        if (page.resize.timeout === false) {
                            page.resize.timeout = true;
                            setTimeout(page.resize.end, page.resize.delta);
                        }
                    }
                },
                true,
            );

            this.$node.querySelector(`#${mapId}_RegionSelect`).addEventListener('change', function (event) {
                const select = event.target;
                window[mapId].page.setCurrentRegion(select.value);
                if (select.value === '') {
                    window[mapId].map.click();
                } else {
                    window[mapId].map.zoomToCurrentRegion();
                }
            });
        },
        initData(mapId) {
            return {
                regions: null,
                map: null,
                currentRegion: null,
            };
        },
        initMap(mapId, mapType) {
            let jsonMapPath, regionId, aggregateAbbreviation;
            if (mapType === '50State') {
                jsonMapPath = '/dist/interactives/maps/us.json';
                regionId = 'states';
                aggregateAbbreviation = 'us';
            } else if (mapType === 'world') {
                jsonMapPath = '/dist/interactives/maps/world.json';
                regionId = 'countries';
                aggregateAbbreviation = 'world';
            } else if (mapType === 'county') {
                jsonMapPath = '/dist/interactives/maps/us.json';
                regionId = 'counties';
                aggregateAbbreviation = 'us';
            }

            return {
                init: function (skipAnimation, skipScroll) {
                    const regionalMap = window[mapId].map;
                    const data = window[mapId].data;
                    if (document.getElementById(mapId)) {
                        document.getElementById(mapId).innerHTML = '';
                        document.getElementById(mapId).classList.remove('hidden');
                    }
                    regionalMap.build(function () {
                        const extraRegions = data.regions.filter((region) => region.nonMapped);
                        regionalMap.addExtraRegions(extraRegions);
                        if (data.currentRegion || skipAnimation) {
                            regionalMap.addRegionAttributes();
                            regionalMap.zoomToCurrentRegion(true);
                        } else {
                            regionalMap.addRegionAttributes(true);
                        }
                    });
                },
                scale: function () {

                    if (document.getElementById('MapObjectContainer').offsetWidth < 600) {
                        if (mapType === 'world') {
                            return 100;
                        }
                        return (
                            document.getElementById('MapObjectContainer').offsetWidth * 1.3
                        );
                    } else if (
                        document.getElementById('MapObjectContainer').offsetWidth > 1200
                    ) {
                        if (mapType === 'world') {
                            return 150;
                        }
                        return 1200;
                    } else {
                        return document.getElementById('MapObjectContainer').offsetWidth;
                    }
                },
                size: function () {
                    const size = {
                        height: 450,
                        width: 1000,
                    };
                    const ratio = 0.6;
                    if (document.getElementById('MapObjectContainer').offsetWidth < 600) {
                        size.width =
                            document.getElementById('MapObjectContainer').offsetWidth;
                    } else if (
                        document.getElementById('MapObjectContainer').offsetWidth > 900
                    ) {
                        size.width = 900;
                    } else {
                        size.width =
                            (document.getElementById('MapObjectContainer').offsetWidth * 9) /
                            10;
                    }
                    size.height = size.width * ratio;
                    return size;
                },
                centerPoint: null,
                path: null,
                g: null,
                build: function (cb) {
                    const regionalMap = window[mapId].map;
                    const ariaLabel = mapType === '50State' ? 'Map of the United States' : 'Map of the World';
                    document.getElementById(mapId).innerHTML = '';
                    let projection;
                    if (mapType === '50State') {
                        projection = d3
                            .geoAlbersUsa()
                            .scale(regionalMap.scale())
                            .translate([regionalMap.size().width / 2, regionalMap.size().height / 2]);
                    } else if (mapType === 'world') {
                        projection = d3
                            .geoMercator()
                            .scale(regionalMap.scale())
                            .translate([regionalMap.size().width / 2, regionalMap.size().height / 2]);
                    }
                    regionalMap.path = d3.geoPath().projection(projection);
                    const svg = d3
                        .select(`#${mapId}`)
                        .append('svg')
                        .attr('class', 'my-0 mx-auto')
                        .attr('aria-label', ariaLabel)
                        .attr('width', regionalMap.size().width)
                        .attr('height', regionalMap.size().height);
                    svg
                        .append('rect')
                        .attr('class', 'background')
                        .attr('width', regionalMap.size().width)
                        .attr('height', regionalMap.size().height)
                        .on('click', regionalMap.click);
                    regionalMap.g = svg.append('g');
                    if (!window[mapId].data.mapJson) {
                        d3.json(jsonMapPath)
                            .then(function (mapJson) {
                                document.querySelector('.loader-container').remove();
                                window[mapId].data.mapJson = mapJson;
                                window[mapId].map.construct(mapJson, function () {
                                    cb();
                                });
                            })
                            .catch(function (err) {
                                console.warn('error while loading map json', err);
                            });
                    } else {
                        regionalMap.construct(window[mapId].data.mapJson, function () {
                            cb();
                        });
                    }
                },
                construct: function (mapJson, cb) {
                    const regionalMap = window[mapId].map;
                    regionalMap.g
                        .append('g')
                        .attr('id', 'regions')
                        .selectAll('path')
                        .data(topojson.feature(mapJson, mapJson.objects[regionId]).features)
                        .enter()
                        .append('path')
                        .attr('data-centroid', regionalMap.path.centroid)
                        .attr('id', function (d) {
                            return d.id;
                        })
                        .attr('d', regionalMap.path)
                        .on('click', regionalMap.click);
                    regionalMap.g
                        .append('path')
                        .datum(
                            topojson.mesh(mapJson, mapJson.objects[regionId], function (a, b) {
                                return a !== b;
                            }),
                        )
                        .attr('id', 'region-borders')
                        .attr('d', regionalMap.path);
                    cb();
                },
                click: function (d, skipScroll) {
                    const id = d?.id ?? d?.value ?? d?.target?.dataset?.id ?? d?.target?.id ?? null;
                    const el = document.getElementById(id);
                    const page = window[mapId].page;
                    const data = window[mapId].data;
                    const regionalMap = window[mapId].map;
                    let x, y;
                    let k = 1;
                    const centroid = el?.dataset?.centroid;
                    if (d && data.centerPoint !== centroid) {
                        if (!el.dataset.clickable) {
                            return;
                        }
                        if (id) {
                            const currentRegion = el.dataset.abbreviation;
                            page.setCurrentRegion(currentRegion, skipScroll);
                            k = regionalMap.getZoomScale(currentRegion, k);
                        } else {
                            k = 2;
                        }

                        const centroidArr = centroid.split(',');
                        if (centroidArr[0]) {
                            x = centroidArr[0];
                            y = centroidArr[1];
                        } else {
                            x = d.x;
                            y = d.y;
                        }

                        window[mapId].data.centerPoint = centroid;
                    } else {
                        x = regionalMap.size().width / 2;
                        y = regionalMap.size().height / 2;
                        data.centerPoint = null;
                        page.general.scrollTo(mapId);
                        if (data.showAggregate) {
                            page.setCurrentRegion(aggregateAbbreviation, true);
                        } else {
                            page.setCurrentRegion();
                        }
                        regionalMap.addRegionAttributes();
                        regionalMap.addLegendStyling();
                    }

                    regionalMap.g
                        .transition()
                        .duration(750)
                        .attr(
                            'transform',
                            `translate(${regionalMap.size().width / 2},${
                                regionalMap.size().height / 2
                            })scale(${k})translate(${-x},${-y})`,
                        )
                        .style('stroke-width', 1.5 / k + 'px');
                },
                getZoomScale: function (currentRegion, k) {

                    let allRegionsRect = d3.select('#regions').node().getBoundingClientRect();

                    let currentRegionSelector = '#regions path[data-abbreviation="' + currentRegion + '"]';
                    let selectedRegionRect = d3.select(currentRegionSelector).node().getBoundingClientRect();

                    let allRegionsArea = allRegionsRect.width * allRegionsRect.height;
                    let selectedRegionArea = selectedRegionRect.width * selectedRegionRect.height;

                    // Range from ~29 (Russia) to ~0.0005 (Luxembourg)
                    let regionAreaRatio = (selectedRegionArea / allRegionsArea) * 100;

                    // Calculate this ratio to identify oblong countries (taller than wide)
                    let heightWidthRatio = (selectedRegionRect.height / allRegionsRect.width) * 100;

                    // Zoom Scale Mapping
                    // This mapping was created by calculating the regionAreaRatio for each region
                    // and then manually identifying breakpoints where the k values worked best
                    switch (true) {
                        case (regionAreaRatio > 5):
                            k = 2;
                            break;
                        case (regionAreaRatio > 1):
                            k = 3;
                            break;
                        case (regionAreaRatio > .7):
                            k = 4;
                            break;
                        case (regionAreaRatio > .3):
                            k = 5;
                            break;
                        case (regionAreaRatio > .1):
                            k = 6;
                            break;
                        case (regionAreaRatio > 0):
                            k = 7;
                            break;
                        default:
                            k = 2; // default to zooming out
                    }

                    // An very opinionated adjustment
                    // Problem: If a region is really tall (North to South) it
                    // might not display properly within the zoomed area
                    // Adjustment logic:
                    // 1. If already zoomed out (level 1 or 2) then don't adjust
                    // 2. If the heightWidthRatio is greater than the arbitrary value 12
                    // then zoom out one more level. The level 12 was manually selected
                    // testing countries such as Norway and Chile
                    if (k > 2 && (heightWidthRatio > 12)) {
                        k -= 1;
                    }

                    return k;
                },
                zoomToCurrentRegion: function (skipScroll) {
                    const regionalMap = window[mapId].map;
                    const data = window[mapId].data;
                    if (!data.currentRegion) {
                        return;
                    }
                    const regions = d3.selectAll('#regions path');
                    regions.each(function (d, i) {
                        if (d3.select(this).attr('data-abbreviation') === data.currentRegion) {
                            regionalMap.click(d3.select(this).datum(), skipScroll);
                        }
                    });
                },
                addExtraRegions: function (regions = []) {
                    let width;
                    let height;
                    regions = regions.reverse();

                    // select regions box
                    const svg = d3.select('#regions');
                    const rect = d3.select('rect.background');
                    const regionalMap = window[mapId].map;

                    // get dimensions
                    const mapDims = rect.node().getBBox();
                    const mapWidth = mapDims.width;
                    if (mapWidth < 600) {
                        height = 18;
                        width = 30;
                    } else {
                        height = 25;
                        width = 40;
                    }

                    let x = mapDims.width - width;
                    let y = mapDims.height - height;

                    regions.forEach((region) => {
                        const data = {
                            id: region.id,
                            abbreviation: region.abbreviation,
                            x: x,
                            y: y,
                        };

                        let path = d3.path();
                        path.rect(x, y, width, height);
                        svg
                            .append('path')
                            .attr('d', path)
                            .attr('class', 'region')
                            .attr('id', region.id)
                            .data([data])
                            .attr('data-centroid', `${x + (width / 2)},${y + (height / 2)}`)
                            .on('click', regionalMap.click);

                        // add text
                        svg
                            .append('text')
                            .text(region.abbreviation.toUpperCase())
                            .attr('x', x + width / 2)
                            .attr('y', y + height / 2)
                            .attr('dominant-baseline', 'middle')
                            .attr('text-anchor', 'middle')
                            .attr('class', 'cursor-pointer select-none')
                            .attr('font-size', 'smaller')
                            .attr('fill', 'white')
                            .attr('data-id', region.id)
                            .data([data])
                            .on('click', regionalMap.click);

                        y = y - (height + 10);
                    });
                },
                addRegionAttributes: function (animate) {
                    const regions = d3.selectAll('#regions path');
                    regions.each(function (d, i) {
                        const tools = window[mapId].tools;
                        const regionalMap = window[mapId].map;
                        if (d.id) {
                            const region = tools.getRegion('id', d.id);
                            if (region) {
                                let shading = region.meta.shading ?? 'shade0';
                                const attributes = {
                                    'data-abbreviation': region.abbreviation,
                                    'data-shading': shading,
                                    'data-clickable': true,
                                };
                                if (animate) {
                                    const that = this;
                                    window.setTimeout(function () {
                                        regionalMap.addAttributes(that, attributes, ['region', 'shaded']);
                                    }, tools.getRandomInt(100, 1100));
                                } else {
                                    regionalMap.addAttributes(this, attributes, ['region', 'shaded']);
                                }
                            }
                        }
                    });
                },
                addAttributes: (region, attributes, classes) => {
                    for (const key in attributes) {
                        region.setAttribute(key, attributes[key]);
                    }
                    classes.forEach((className) => {
                        region.classList.add(className);
                    });
                },
                removeStyling: (region = {}) => {
                    if (!region.abbreviation || region.abbreviation === aggregateAbbreviation) {
                        return;
                    }
                    const shading = region.meta.shading;
                    document.querySelectorAll('.region').forEach(function (region) {
                        region.classList.remove(
                            'shaded',
                        );
                    });
                    const selectedRegion = document.querySelector(`[data-abbreviation="${region.abbreviation}"]`);
                    if (selectedRegion) {
                        selectedRegion.classList.add('shaded');
                        window[mapId].map.greyOutLegend(shading);
                    }
                },
                addLegendStyling: function () {
                    const legendItems = document.querySelectorAll('.legend-item');
                    // loop through legend items and pull data-shade value into class
                    legendItems.forEach(function (item) {
                        const shade = item.dataset.shade;
                        item.classList.add(`legend-${shade}`);
                    });
                },
                greyOutLegend: function (selectedClass) {
                    if (!selectedClass) {
                        return;
                    }
                    const legendItems = document.querySelectorAll('.legend-item');
                    legendItems.forEach(function (item) {
                        if (item.dataset.shade !== selectedClass) {
                            item.classList.remove(`legend-${item.dataset.shade}`);
                        } else {
                            item.classList.add(`legend-${item.dataset.shade}`);
                        }
                    });
                },
            };
        },
        initPage(mapId, queryParamName, aggregateAbbreviation) {
            return {
                mobileThreshold: 870,
                width: null,
                scrolled: false,
                resize: {
                    rtime: null,
                    timeout: false,
                    delta: 200,
                    end: () => {
                        const regionalMap = window[mapId].map;
                        const page = window[mapId].page;
                        if (new Date() - page.resize.rtime < page.resize.delta) {
                            setTimeout(page.resize.end, page.resize.delta);
                        } else {
                            page.resize.timeout = false;
                            regionalMap.init(true, true);
                        }
                    },
                },
                footer: {
                    show: (region) => {
                        const page = window[mapId].page;
                        if (!region) {
                            return;
                        }
                        // show region specific information
                        page.general.slideDown(
                            document.getElementById(`RegionTemplateContent_${region.abbreviation}`),
                        );
                        // show the exit button
                        if (region.abbreviation !== aggregateAbbreviation) {
                            document.getElementById('MapExit').classList.remove('hidden');
                        }
                    },
                    hideAll: () => {
                        const page = window[mapId].page;
                        // hide all region specific information
                        const footerRegionData =
                            document.querySelectorAll('.region-data');
                        for (let i = 0; i < footerRegionData.length; i++) {
                            page.general.slideUp(footerRegionData[i]);
                        }
                        // hide the exit button
                        document.getElementById('MapExit').classList.add('hidden');
                    },
                },
                init: () => {
                    const page = window[mapId].page;
                    document.getElementById(mapId).classList.remove('hidden');
                    page.general.addListeners();
                },
                general: {
                    slideDown: (elem) => {
                        elem.style.transition = 'height 0.3s ease-in-out';
                        elem.style.height = `${elem.scrollHeight}px`;
                        window.setTimeout(() => {
                            elem.style.height = `auto`;
                        }, 300);
                    },
                    slideUp: (elem) => {
                        elem.style.transition = 'height 0.3s ease-in-out';
                        elem.style.height = `0px`;
                    },
                    addListeners: () => {
                        document.getElementById('MapExit').addEventListener('click', () => {
                            const regionalMap = window[mapId].map;
                            regionalMap.click();
                        });
                    },
                    scrollTo: (loc) => {
                        const page = window[mapId].page;
                        const data = window[mapId].data;
                        if (loc === 'stats' && !page.scrolled) {
                            const currentScroll = window.pageYOffset; // offset between doc & viewport
                            const elementTop = document
                                .querySelector('[data-region-template]')
                                .getBoundingClientRect().top; // offset between viewport and element
                            const containerTop = document
                                .getElementById('MapContainer')
                                .getBoundingClientRect().height; // height of map container
                            let scrollLoc = currentScroll + elementTop - containerTop / 2;
                            if (data.config.singleScroll) {
                                page.scrolled = true;
                            }
                            window.scroll({
                                top: scrollLoc,
                                behavior: 'smooth',
                            });
                        }
                    },
                },
                setCurrentRegion: (regionAbbr = '', freeze) => {
                    const page = window[mapId].page;
                    const tools = window[mapId].tools;
                    const data = window[mapId].data;
                    const regionalMap = window[mapId].map;
                    tools.setQueryParam(queryParamName, regionAbbr);
                    page.footer.hideAll();
                    data.currentRegion = regionAbbr;
                    if (regionAbbr === '') {
                        this.$node.querySelector(`#${mapId}_RegionSelect`).value = '';
                        return;
                    }
                    const region = tools.getRegion('abbreviation', regionAbbr);
                    this.$node.querySelector(`#${mapId}_RegionSelect`).value = regionAbbr;
                    if (regionAbbr === aggregateAbbreviation) {
                        document.getElementById('MapExit').classList.add('hidden');
                    } else {
                        document.getElementById('MapExit').classList.remove('hidden');
                    }
                    page.footer.show(region);
                    regionalMap.removeStyling(region);
                    if (freeze !== true) {
                        page.general.scrollTo('stats');
                    }
                },
            };
        },
        initTools(mapId) {
            return {
                getRegion: function (key, val) {
                    const data = window[mapId].data;
                    for (let i = 0; i < data.regions.length; i++) {
                        if (data.regions[i][key] === val) {
                            return data.regions[i];
                        }
                    }
                    return null;
                },
                getRandomInt: function (min, max) {
                    min = Math.ceil(min);
                    max = Math.floor(max);
                    return Math.floor(Math.random() * (max - min)) + min;
                },
                getQueryParam: (param) => {
                    const urlParams = new URLSearchParams(window.location.search);
                    const selectedVar = urlParams.get(param);
                    if (selectedVar) {
                        return selectedVar;
                    }
                    return false;
                },
                setQueryParam: (param, value) => {
                    // get all query params in url
                    const urlParams = new URLSearchParams(window.location.search);
                    // if the param exists, update it
                    if (urlParams.has(param)) {
                        urlParams.set(param, value);
                    } else {
                        // if it doesn't exist, add it
                        urlParams.append(param, value);
                    }
                    // update the url
                    if (history.pushState) {
                        const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + urlParams.toString();
                        window.history.pushState({path: newUrl}, '', newUrl);
                    }

                },
            };
        },
    },
    {
        init() {
            const element = this.$node;
            const mapId = element.dataset.mapId;
            const mapType = element.dataset.mapType ?? '50State';
            const preLoadedRegion = element.dataset.preloadedRegion;
            let queryParamName, aggregateAbbreviation;
            if (mapType === '50State') {
                queryParamName = 'state';
                aggregateAbbreviation = 'us';
            } else if (mapType === 'world') {
                queryParamName = 'country';
                aggregateAbbreviation = 'world';
            } else if (mapType === 'usCounties') {
                queryParamName = 'county';
                aggregateAbbreviation = 'us';
            }
            const mapElId = `Map_${mapId}`;
            this.addEventListeners(mapElId);
            window[mapElId] = {};
            window[mapElId].data = this.initData(mapElId);
            window[mapElId].map = this.initMap(mapElId, mapType);
            window[mapElId].page = this.initPage(mapElId, queryParamName, aggregateAbbreviation);
            window[mapElId].tools = this.initTools(mapElId);
            window[mapElId].data.regions = JSON.parse(
                document.getElementById(`${mapId}_Data`).textContent,
            );
            window[mapElId].data.config = JSON.parse(
                document.getElementById(`${mapId}_Config`).textContent,
            );
            window[mapElId].data.currentRegion =
                window[mapElId].tools.getQueryParam(queryParamName);
            window[mapElId].map.init();
            window[mapElId].page.init();
            window[mapElId].data.showAggregate =
                window[mapElId].tools.getRegion('abbreviation', aggregateAbbreviation) !== null;
            if (preLoadedRegion && !window[mapElId].tools.getRegion('abbreviation', preLoadedRegion)) {
                return;
            }
            if (
                window[mapElId].data.showAggregate &&
                (!window[mapElId].data.currentRegion ||
                    window[mapElId].data.currentRegion === aggregateAbbreviation)
            ) {
                window[mapElId].page.setCurrentRegion(aggregateAbbreviation, true);
            } else if (preLoadedRegion) {
                console.log('preLoadedRegion', preLoadedRegion);
                window[mapElId].page.setCurrentRegion(preLoadedRegion, true);
            }
        },
    },
);

export default map;
