import {createBehavior} from '@area17/a17-behaviors';

const lazyloadimg = createBehavior('lazyloadimg',
    {},
    {
        init() {
            this.lazyloaded = false;
            this.$img = this.getChild('img');
            this.$sources = this.getChildren('source');
        },
        intersectionIn() {
            if (!this.lazyloaded) {
                if (this.$img) {
                    if (this.$img.dataset.srcset && !this.$img.srcset) {
                        this.$img.srcset = this.$img.dataset.srcset;
                    }
                    if (this.$img.dataset.src && !this.$img.src) {
                        this.$img.src = this.$img.dataset.src;
                    }
                }
                if (this.$sources.length) {
                    this.$sources.forEach($source => {
                        if ($source.dataset.srcset && !$source.srcset) {
                            $source.srcset = $source.dataset.srcset;
                        }
                    });
                }
            }
        },
    },
);

export default lazyloadimg;
