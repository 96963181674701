import {createBehavior} from '@area17/a17-behaviors';

const filterBlock = createBehavior(
    'filterBlock',
    {

        addEventListeners() {
            // init listeners on all elements


            // this.selects = this.getChildren('select');
            this.selects = this.$node.querySelectorAll('div[data-filterblock-select]');
            this.selects.forEach((elem) => {
                if (this.$node.dataset.runQueryOnChange === 'true' && elem.dataset.secondaryFilter !== 'true') {
                    elem.querySelector('select').addEventListener('change', (ev) => {
                        this.handleSelectChange(ev);
                    });
                }
                if (elem.dataset.secondaryFilter === 'true') {
                    elem.querySelector('select').addEventListener('change', (ev) => {
                        this.handleSelectChange(ev, elem.dataset.secondaryFilter);
                    });
                }
            });

            const pageQueryParams = new URLSearchParams(window.location.search);
            // has to be set at window level because this behavior is reset after each successful search
            this.lastSearchQuery = pageQueryParams.get('q')
                ? pageQueryParams.get('q')
                : '';

            const search = this.getChild('search');
            if (search) {
                this.search = search.querySelector('input');
                if (this.$node.dataset.runQueryOnChange === 'true') {
                    this.search.addEventListener('keyup', this.handleSearchKeyUp);
                    this.search.addEventListener('blur', this.handleSearchBlur);
                }
            }

            const pagination = this.$node.querySelector(
                '[data-component=\'block:pagination\']',
            );
            if (pagination) {
                const pageButtons = pagination.querySelectorAll('li');
                for (let i = 0; i < pageButtons.length; i++) {
                    pageButtons[i].addEventListener('click', this.handlePaginationClick);
                }
            }

            if (this.$node.dataset.runQueryOnChange === 'false') {
                const applyButton = this.$node.querySelector('[data-filterblock-apply]');
                applyButton.addEventListener('click', () => {
                    this.applyFilters();
                });

                const resetButton = this.$node.querySelector('[data-filterblock-reset]');
                resetButton.addEventListener('click', () => {
                    this.resetFilters();
                });
            }
        },

        // handlers

        handleSelectChange(ev, hasSecondary = false) {
            if (hasSecondary) {
                // hide all secondary filters using data-parent-id={{ filterId }}
                this.$node.querySelectorAll('[data-parent-id="' + ev.target.id + '"]').forEach((el) => {
                    el.classList.add('hidden');
                });
                // find secondary filter and reveal it
                this.$node.querySelector('[data-container-id="' + ev.target.id + '_' + ev.target.value + '"]').classList.remove('hidden');
            } else {
                this.applyFilters({type: 'select', data: {target: ev.target}});
            }
        },

        handlePaginationClick(ev) {
            ev.preventDefault();
            const href = new URL(ev.target.href);
            const pageNumber = href.searchParams.get('page');
            this.currentPageNumber = pageNumber;
            this.applyFilters({type: 'pagination', data: pageNumber});
        },

        handleSearchKeyUp(ev) {
            if (ev.key === 'Enter') {
                this.applyFilters({type: 'search', data: ev.target.value});
            } else if (ev.key === 'Escape') {
                ev.target.value = '';
                ev.target.blur();
            }
        },

        handleSearchBlur(ev) {
            if (ev.target.value === '' && this.lastSearchQuery !== '') {
                this.applyFilters({type: 'search', data: ''});
            }
        },

        resetFilters() {
            this.selects.forEach((el) => {
                el.querySelector('select').value = '';
            });
            if (this.search) {
                this.search.value = '';
            }
            this.applyFilters();
        },

        getFilterValues(caller) {
            const filters = {};
            // get the updates from the select elements
            this.selects.forEach((el) => {
                const value = el.querySelector('select').value;
                const key = el.dataset.filterType;
                filters[key] = value;
            });
            // get the updates from the search input
            if (this.search) {
                filters.q = this.search.value === '' ? null : this.search.value;
                this.lastSearchQuery = this.search.value;
            }
            // clear out the page filter since we are changing the filters
            if (caller.type === 'pagination') {
                filters.page = caller.data;
            } else {
                filters.page = null;
            }
            return filters;
        },

        // called on any change to filters/pagination/tabs
        async applyFilters(caller = {}, dryRun = false, returnFiltersOnly = false) {
            // get the current filters embedded in the page
            const currentPageUrlParams = new URLSearchParams(window.location.search);
            const requestUrl = new URL(this.feed.dataset.actionUrl);

            const filters = this.getFilterValues(caller);
            for (const filter in filters) {
                const value = filters[filter];
                if (value && value !== '') {
                    requestUrl.searchParams.set(filter, filters[filter]);
                    currentPageUrlParams.set(filter, filters[filter]);
                } else {
                    currentPageUrlParams.delete(filter);
                }
            }
            if (this.tab) {
                requestUrl.searchParams.set('tab', this.tab);
            }

            this.updateUrl(currentPageUrlParams);

            if (dryRun) {
                return;
            }

            try {
                this.toggleLoadingState();
                const componentHtml = await this.getData(requestUrl);
                this.updateDomWithContent(componentHtml);
                this.toggleLoadingState();
                // since whole DOM is swapped we need to re-init the behavior
                this.resetJSAttachments();
                this.addEventListeners();

            } catch (e) {
                console.error(e);
                this.toggleLoadingState();
            }


        },

        async getData(url) {
            try {
                const bodyParams = new URLSearchParams();
                bodyParams.set('class', this.phpClass);
                // make the request
                url.searchParams.set('elementId', this.elementId);
                return await USCC.ajax(
                    url.toString(),
                    'POST',
                    bodyParams.toString(),
                );
            } catch (e) {
                throw(e);
            }
        },

        activateLoadingSymbol() {
            this.animationAngle += 0.25;
            this.$node
                .querySelector('[data-component="atom:logo-animated"]')
                .style.setProperty('--rotate-base', `${this.animationAngle}deg`);
            this.logoAnimationId = window.requestAnimationFrame(this.activateLoadingSymbol);
        },

        updateUrl(params = {}) {
            const url = new URL(window.location);
            // clear out all searchParams
            url.search = '';
            params.forEach((value, key) => {
                url.searchParams.set(key, value);
            });
            window.history.pushState({}, '', url.toString());
        },

        toggleLoadingState() {
            if (!this.loading) {
                this.activateLoadingSymbol();
                // prepare dom for animation
                this.$node.scrollIntoView(true);
                this.$node.querySelector('ul').classList.add('hidden');
                this.$node.setAttribute('aria-busy', 'true');
                this.$node
                    .querySelector('[data-component="atom:logo-animated"]')
                    .classList.remove('hidden');
                this.loading = true;
            } else {
                window.cancelAnimationFrame(this.logoAnimationId);
                this.$node.setAttribute('aria-busy', 'false');
                this.loading = false;
            }
        },

        updateDomWithContent(content) {
            // replace the content
            const doc = new DOMParser().parseFromString(content, 'text/html');
            this.$node.innerHTML = doc.querySelector(
                '[data-content-feed-wrapper]',
            ).innerHTML;

        },

        resetJSAttachments() {
            this.filters = {};
            this.search = null;
            this.animationAngle = 0;
            this.loading = false;
            this.feed = this.getChild('content-feed');

        },
    },

    {
        init() {
            this.phpClass = this.$node.dataset.phpClass;
            this.resetJSAttachments();
            this.addEventListeners();
            this.elementId = this.$node.dataset.elementId;

            // if this exists on a tab, and you leave that tab, and then come back
            // the filters will still be applied but the URL will be wrong
            // so we need to update the URL
            this.tab = this.$node.dataset.filterblockTabIndex ?? null;
            document.addEventListener('tab:change', (ev) => {
                if (ev.detail.index === parseInt(this.tab)) {
                    this.applyFilters({type: 'pagination', data: this.currentPageNumber}, true);
                }
            });
        },
    },
);

export default filterBlock;
