const generateDataMap = (currentFilters, textFilters) => {
    const dataMap = {};
    currentFilters.forEach((item) => {
        if (item.param in dataMap) {
            dataMap[item.param].push(item.value);
        } else {
            dataMap[item.param] = [];
            dataMap[item.param].push(item.value);
        }
    });

    // append text values to dataMap
    textFilters.forEach((elem) => {
        // => data looks like this
        // => <div data-filtertype="{{item.type}}" data-trigger="{{item.param}}" ><input /></div>
        const input = elem.querySelector('input');
        const inputValue = input.value;
        const inputParam = elem.getAttribute('data-trigger');
        if (inputValue) {
            dataMap[inputParam] = inputValue;
        }
    });

    return dataMap;
};

const generateChip = (data) => {
    return `
<button data-value="${data.value}" data-param="${data.param}"  data-component="atom:chip" class="
  chip
  flex
  bg-light
  py-4
  px-12
  rounded-full
  hover:bg-accent-secondary
  f-ui-2
  items-center
  h-28
  flex-shrink-0
">
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="block mr-4 h-12 w-12"><rect x="5.28249" y="4.57538" width="20" height="1.5" transform="rotate(45 5.28249 4.57538)" fill="currentColor"></rect><rect width="20" height="1.5" transform="matrix(-0.707107 0.707107 0.707107 0.707107 18.7175 4.57538)" fill="currentColor"></rect></svg>
${data.label}
</button>`;
};

export {generateChip, generateDataMap};
