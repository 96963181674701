const addBlankTargetToPdfLinks = (limitToCasesPages) => {
  const links = document.getElementsByTagName('a');

  for (let i = 0; i < links.length; i++) {
    const link = links[i];
    const href = link.getAttribute('href');

    if (limitToCasesPages && !window.location.pathname.includes('/cases')) {
      continue;
    }

    if (href && href.includes('.pdf')) {
      link.setAttribute('target', '_blank');
    }
  }
};

export default addBlankTargetToPdfLinks;

