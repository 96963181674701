import {createBehavior} from '@area17/a17-behaviors';

/** YouTube player states
 -1 (unstarted)
 0 (ended)
 1 (playing)
 2 (paused)
 3 (buffering)
 5 (video cued)
 **/

const videoPlayer = createBehavior(
    'videoPlayer',
    {
        initPlayer() {
            this.player = new YT.Player(this.$player, {
                playerVars: {},
                events: {
                    onStateChange: this.onPlayerStateChange,
                    onReady: this.onPlayerReady,
                },
            });
        },
        onPlayerReady() {
        },
        onPlayerStateChange(ev) {
        },

        unMute() {
            this.player.unMute();
        },
        setCurrentTime(ev) {
            ev.preventDefault();
            const seconds = ev.target.dataset.seconds;
            this.player.seekTo(seconds, true);
        },
        dispatchResizeEvent() {
            document.dispatchEvent(
                new CustomEvent('videoPlaylist:resetHeight', {
                    detail: {
                        height: this.$player.offsetHeight,
                    },
                }),
            );
        },
    },
    {
        init() {
            this.$player = this.$node.querySelector(
                '[data-component="atom:video"]',
            );

            window.addEventListener('resize', this.dispatchResizeEvent);

            const timestamps = document.querySelectorAll(
                'div[data-component="block:editorial-list"] li',
            );

            for (let i = 0; i < timestamps.length; i++) {
                timestamps[i].addEventListener('click', this.setCurrentTime);
            }

            window.onYouTubeIframeAPIReady = () => {
                document.dispatchEvent(new CustomEvent('youtubeApiReady'));
            };

            // append youtube frame api
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            tag.async = true;
            document.head.appendChild(tag);

            document.addEventListener('youtubeApiReady', this.initPlayer, false);
        },
        destroy() {
        },
    },
);

export default videoPlayer;
