export function setScrollbarWidth() {
    let scrollbox = document.createElement('div');
    scrollbox.style.overflow = 'scroll';
    document.body.appendChild(scrollbox);

    // Measure inner width of box
    const scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;

    document.body.removeChild(scrollbox);
    document.documentElement.style.setProperty('--scroll-bar-width', `${scrollBarWidth}px`);
}

export default setScrollbarWidth;
