import {createBehavior} from '@area17/a17-behaviors';
import {generateChip, generateDataMap} from '../helpers/multiSelect';

const multiSelect = createBehavior(
    'multiSelect',
    {
        closeOverlay() {
            document.dispatchEvent(new CustomEvent('body:unlock'));
            document.documentElement.classList.remove('s-multi-select-menu-open');
            this.$activeOverlay.classList.remove('s-active');
            document.dispatchEvent(new CustomEvent('focus:untrap'));

            if (this.$opener) {
                this.$opener.focus();
            }
            this.$opener = null;
            this.$activeOverlay = null;
        },

        openOverlay() {
            // set the correct checked values of the overlay
            this.setCheckedValues();
            // move current data into temp (in case user does not save)
            this.setData();
            // update the count next to overaly title
            this.updateOverlayCount();
            // show the desired panel
            this.$activeOverlay.classList.add('s-active');
            document.documentElement.classList.add('s-multi-select-menu-open');
            document.dispatchEvent(new CustomEvent('body:lock'));
            document.dispatchEvent(
                new CustomEvent('focus:trap', {
                    detail: {
                        element: this.$activeOverlay,
                    },
                }),
            );
            const $closeBtn = this.$activeOverlay.querySelector(`[data-close]`);
            if ($closeBtn) {
                $closeBtn.focus();
            }
        },

        updateOverlayCount() {
            this.overlays.forEach((overlay) => {
                const param = overlay.getAttribute('data-overlayparam');
                const countElem = overlay.querySelector('[data-overlaycount]');
                let count = 0;

                // if no array items set all counts to empty
                if (this.tempFilters.length == 0) {
                    countElem.innerHTML = '';
                } else {
                    this.tempFilters.forEach((filterItem) => {
                        if (filterItem.param === param) {
                            count++;
                        }
                    });
                    if (count === 0) {
                        countElem.innerHTML = '';
                    } else {
                        countElem.innerHTML = ` (${count})`;
                    }
                }
            });
        },

        setCheckedValues() {
            this.tempFilters = [...this.currentFilters];
            const buttons = [
                ...this.$activeOverlay.querySelectorAll(`[data-selectitem]`),
            ];
            buttons.forEach((checkbox) => {
                checkbox.checked = false;
            });

            this.currentFilters.forEach((item) => {
                item.el.checked = true;
            });
        },

        removeValue(filterItem) {
            const result = this.tempFilters.filter((item) => {
                if (filterItem.value === item.value) {
                    // do not return this value - it is being removed
                } else {
                    return item;
                }
            });

            this.tempFilters = result;
            this.updateOverlayCount();
        },

        addValue(item) {
            const label = item.getAttribute('data-label');
            const param = item.getAttribute('data-param');
            const value = item.value;
            this.tempFilters.push({
                el: item,
                label,
                value,
                param, // overlay param
            });
            this.updateOverlayCount();
        },

        clearAllOverlayValues(param) {
            const filteredValues = this.tempFilters.filter((item) => {
                if (item.param !== param) {
                    return item;
                } else {
                    item.el.checked = false;
                }
            });
            this.tempFilters = filteredValues;
            this.updateOverlayCount();
        },

        // this generates a param string of data for the url
        gatherFilterParams() {
            const dataMap = generateDataMap(
                this.currentFilters,
                this.textFilters,
            );

            const queryParams = new URLSearchParams(dataMap).toString();

            const cleanUrl = window.location.href.split('?')[0];
            history.pushState('', '', `${cleanUrl}?${queryParams}`);
        },

        addOverlayButtons(overlay) {
            const param = overlay.getAttribute('data-overlayparam');
            const buttons = [...overlay.querySelectorAll(`[data-selectitem]`)];
            buttons.forEach((button) => {
                button.addEventListener('change', () => {
                    if (button.checked) {
                        this.addValue(button);
                    } else {
                        this.removeValue(button);
                    }
                });
            });

            const submitButton = overlay.querySelector(`[data-submit]`);
            submitButton.addEventListener('click', () => {
                this.closeOverlay();
                this.setData();
                buttons.forEach((checkbox) => {
                    checkbox.checked = false;
                });
            });

            // clear button triggers
            // when you clear on an overlay, ALL overlay values of this unique param
            // will be cleard and unchecked
            const clearButton = overlay.querySelector(`[data-clear]`);
            clearButton.addEventListener('click', () => {
                this.clearAllOverlayValues(param);
                this.setData();
            });

            // overlay close triggers
            // when you close the overaly, temp values will not replace filter set
            // current filters and removed
            const closeTrigger = overlay.querySelector('[data-close]');
            closeTrigger.addEventListener('click', () => {
                this.closeOverlay(overlay);
                console.log('no data was replaced...');
            });
        },

        updateChips() {
            let htmlString = ``;
            this.currentFilters.forEach((filterData) => {
                htmlString += generateChip(filterData);
            });
            this.list.innerHTML = '';
            this.list.innerHTML = htmlString;
        },

        initChipsTrigger() {
            this.list.addEventListener('click', (e) => {
                const currentChip = e.target.closest('.chip');
                if (currentChip) {
                    this.tempFilters = this.currentFilters;
                    this.removeValue({
                        value: currentChip.getAttribute('data-value'),
                        param: currentChip.getAttribute('data-param'),
                    });
                    this.setData();
                    this.updateChips();
                }
            });
        },

        setData() {
            this.currentFilters = [...this.tempFilters];
            this.gatherFilterParams();
            this.updateChips();
        },

        fetchData() {
            // here is where we will gather params and ajax data
        },

        initValues() {
            // get query string with all params
            const parsed = new URLSearchParams(location.search);

            // flatten all the values in the parsed data (this is an optimization for setting values)
            let flatValues = [];
            for (const [key, val] of parsed.entries()) {
                let value = val.indexOf(',') > -1 ? val.split(',') : val;
                if (this.textParams.includes(key)) {
                    const inputElem = this.$node.querySelector(
                        `[data-trigger="${key}"]`,
                    );
                    inputElem.querySelector('input').value = value;
                } else {
                    // merge all select values, ommiting the input values - setting them seperatly
                    flatValues = [...flatValues, ...value];
                }
            }

            const allCheckboxes = [
                ...this.$node.querySelectorAll(`[data-selectitem]`),
            ];

            allCheckboxes.forEach((elem) => {
                if (flatValues.includes(elem.value)) {
                    this.addValue(elem);
                }
            });
            this.setData();
        },

        keyup(evt) {
            switch (evt.keyCode) {
                case 27:
                    if (this.$activeOverlay) {
                        this.closeOverlay();
                    }
                    break;
            }
        },

        maskClicked() {
            if (this.$activeOverlay) {
                this.closeOverlay();
            }
        },

        filterClicked(e) {
            this.$opener = e.currentTarget;
            const trigger = this.$opener.getAttribute('data-trigger');
            const currentOverlay = this.$node.querySelector(
                `[data-overlayparam="${trigger}"]`,
            );
            this.$activeOverlay = currentOverlay;
            if (this.$activeOverlay) {
                this.openOverlay();
            }
        },
    },
    {
        init() {
            // states
            this.$activeOverlay = null;
            this.$opener = null;
            this.currentFilters = [];
            this.tempFilters = [];
            this.textParams = [];

            // elems
            this.list = this.getChild('list');

            this.$node.classList.add('s-overlays-ready');

            // collections
            this.filters = [
                ...this.$node.querySelectorAll('[data-filtertype="select"]'),
            ];
            this.textFilters = [
                ...this.$node.querySelectorAll('[data-filtertype="text"]'),
            ];

            this.overlays = [...this.$node.querySelectorAll('[data-overlay]')];

            this.textFilters.forEach((item) => {
                const param = item.getAttribute('data-trigger');
                this.textParams.push(param);
            });

            this.initValues();
            this.initChipsTrigger();

            this.textFilters.forEach((elem) => {
                const submit = elem.querySelector('button');
                submit.addEventListener('click', this.gatherFilterParams, false);
            });

            document.addEventListener('mask:clicked', this.maskClicked, false);

            this.overlays.forEach((overlay) => {
                this.addOverlayButtons(overlay);
            });

            this.filters.forEach((filter) => {
                filter.addEventListener('click', this.filterClicked, false);
            });

            document.addEventListener('keyup', this.keyup, false);
        },

        destroy() {
            this.textFilters.forEach((elem) => {
                const submit = elem.querySelector('button');
                submit.removeEventListener('click', this.gatherFilterParams);
            });
            document.removeEventListener('mask:clicked', this.maskClicked);
            this.filters.forEach((filter) => {
                filter.removeEventListener('click', this.filterClicked);
            });
            document.removeEventListener('keyup', this.keyup);
        },
    },
);

export default multiSelect;
