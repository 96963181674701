import {focusDisplayHandler, focusTrap, ios100vhFix} from '@area17/a17-helpers';
import {manageBehaviors} from '@area17/a17-behaviors';
import * as Behaviors from './behaviors';
import DOMReady from './helpers/DOMReady.js';
import lockBody from './functions/lockBody.js';
import setScrollbarWidth from './functions/setScrollbarWidth.js';
import ajax from './helpers/ajax.js';
import checkCmsPaths from './functions/checkCmsPaths';
import scrollToFeed from './functions/scrollToFeed';
import modifyDom from './functions/modifyDom';
import addBlankTargetToPdfLinks from './functions/addTargetBlankToPdfLinks';
import cssConfig from '@css/app.config.json';

// Import CSS
import "@css/app.css";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

checkCmsPaths();

DOMReady(() => {
    window.USCC = window.USCC || {};
    window.USCC.ajax = ajax;
    window.USCC.structure = cssConfig.structure;

    let breakpoints = [];
    if (cssConfig.structure && cssConfig.structure.breakpoints) {
        for (const [key, value] of Object.entries(cssConfig.structure.breakpoints)) {
            breakpoints.push({name: key, start: value});
        }
        breakpoints = breakpoints.sort(
            (a, b) => parseInt(a.start) - parseInt(b.start),
        );
        breakpoints = breakpoints.map((a) => a.name);
    }

    // Prepare breakpoints for @area17/a17-behaviors package
    window.A17 = window.A17 || {};
    window.A17.behaviors = manageBehaviors;
    window.A17.behaviors.init(Behaviors, {
        breakpoints: breakpoints
    });

    // adds a `--1vh` CSS variable to `:root`
    ios100vhFix();

    // set css variable for scroll bar width
    setScrollbarWidth();

    // workout focus type
    focusDisplayHandler();

    // listen for focus trapping requests
    focusTrap();

    // listen for lock body requests
    lockBody();

    // check for the need to update host on a tags
    scrollToFeed();

    // dynamically load querly scripts
    modifyDom();

    // add target="_blank" to all links containing .pdf within /cases
    addBlankTargetToPdfLinks();

    /*
    document.addEventListener('keyup', () => {
      const active = document.activeElement;
      const styles = window.getComputedStyle(active);
      console.log('active element:', active.textContent.trim(), styles.getPropertyValue('visibility'));
    }, false);
    /**/
});
