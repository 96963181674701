import {createBehavior} from '@area17/a17-behaviors';

const heroFeature = createBehavior(
    'heroFeature',
    {
        headerTop() {
            // trying to account for the hide/showing header
            if (!this.desktop) {
                this.$primarycontainer.style.top = '0px';
            } else {
                this.$primarycontainer.style.top = '';
            }
        },
        headerAbsolute() {
            // trying to account for the hide/showing header
            if (!this.desktop) {
                this.$primarycontainer.style.top = '0px';
            } else {
                this.$primarycontainer.style.top = '';
            }
        },
        headerFixed() {
            // trying to account for the hide/showing header
            if (!this.desktop) {
                this.$primarycontainer.style.top = 'var(--header-height)';
            }
        },
        showSecondaryText() {
            this.$secondaryText.style.opacity = 1;
            this.$secondaryText.style.transform = 'translateY(0px)';
        },
        hideSecondaryText() {
            this.$secondaryText.style.opacity = 0;
            this.$secondaryText.style.transform = 'translateY(25px)';
        },
        showPrimaryText() {
            if (!this.primaryTextVisible) {
                this.$primaryText.firstElementChild.style.opacity = 1;
                this.$primaryText.firstElementChild.style.transform = 'translateY(0px)';
                this.primaryTextVisible = true;
            }
        },
        hidePrimaryText() {
            if (this.primaryTextVisible) {
                this.$primaryText.firstElementChild.style.opacity = 0;
                this.$primaryText.firstElementChild.style.transform = 'translateY(-50px)';
                this.primaryTextVisible = false;
            }
        },
        rotateSvg(svg) {
            let rotate = (this.scrollInit - this.scrollY) / this.rotateThrottle;
            if (this.options.rotation === 'anticlockwise') {
                rotate *= -1;
            }
            this.$node.style.setProperty('--rotate', `${rotate}deg`);
        },
        intersected(entries) {
            entries.forEach(entry => {
                // the request animation frame loop is stopped if this node isn't in the viewport
                if (entry.target === this.$node) {
                    this.intersecting = entry.isIntersecting;
                    if (entry.isIntersecting) {
                        this.loop();
                    }
                }
                // the primary svg isn't animated when its out of the viewport
                if (entry.target === this.$primarySvg) {
                    this.primarySvgIntersecting = entry.isIntersecting;
                }
                // the secondary svg isn't animated when its out of the viewport
                if (entry.target === this.$secondarySvg) {
                    this.secondarySvgIntersecting = entry.isIntersecting;
                }
            });
        },
        loop() {
            if (!this.intersecting) {
                return;
            }

            const scrollY = window.pageYOffset;
            // don't act if we're not scrolling
            if (this.scrollY !== scrollY) {
                this.scrollY = scrollY;
                // if svgs exist and are intersected rotate them
                if ((this.$primarySvg && this.primarySvgIntersecting) || (this.$secondarySvg && this.secondarySvgIntersecting)) {
                    this.rotateSvg(this.$primarySvg);
                }
                // when the secondary image or svg hits the primary text, hide it
                if (this.$secondaryImage) {
                    if (this.$secondaryImage.getBoundingClientRect().top < this.$primaryText.getBoundingClientRect().bottom) {
                        this.hidePrimaryText();
                    } else {
                        this.showPrimaryText();
                    }
                }
                // when the secondary text is above `secondaryRevealPos` then show it
                // where `secondaryRevealPos` is something like 1/3 of the screen height
                if (this.isBreakpoint('lg+') && this.$secondaryContainer) {
                    if (this.windowH - this.$secondaryContainer.getBoundingClientRect().top > this.secondaryRevealPos) {
                        this.showSecondaryText();
                    } else {
                        this.hideSecondaryText();
                    }
                }
            }

            window.requestAnimationFrame(this.loop);
        },
    },
    {
        init() {
            this.scrollY = -1;
            this.scrollInit = 0;
            this.windowH = window.innerHeight;
            this.secondayRevealPercent = 0.33;
            this.rotateThrottle = 100;
            this.secondaryRevealPos = Math.round(this.windowH * this.secondayRevealPercent);
            this.desktop = this.isBreakpoint('lg+');
            this.primaryTextVisible = true;
            this.secondaryTextVisible = false;
            this.primarySvgIntersecting = true;
            this.secondarySvgIntersecting = false;

            this.$content = this.getChild('content');
            this.$primarySvg = this.getChild('primarysvg');
            this.$secondarySvg = this.getChild('secondarysvg');
            this.$primaryText = this.getChild('primarytext');
            this.$primarycontainer = this.getChild('primarycontainer');
            this.$secondaryText = this.getChild('secondarytext');
            this.$secondaryContainer = this.getChild('secondarycontainer');
            this.$secondaryImage = this.getChild('secondaryimage');

            this.intersecting = false;
            this.io = new IntersectionObserver(this.intersected);
            this.io.observe(this.$node);

            if (this.$primarySvg) {
                const svg = this.$primarySvg.querySelector('svg');
                if (svg) {
                    this.$primarySvg = svg;
                    this.io.observe(this.$primarySvg);
                    this.$primarySvg.style.backfaceVisibility = 'hidden';
                    this.$primarySvg.style.willChange = 'transform';

                    setTimeout(() => {
                        this.$primarySvg.style.setProperty('--transition-duration', '0ms');
                    }, (
                        parseInt(getComputedStyle(this.$primarySvg).getPropertyValue('--transition-duration'), 10) || 500
                    ));
                }
            }

            if (this.$secondarySvg) {
                const svg = this.$secondarySvg.querySelector('svg');
                if (svg) {
                    this.$secondarySvg = svg;
                    this.io.observe(this.$secondarySvg);
                    this.$secondarySvg.style.backfaceVisibility = 'hidden';
                    this.$secondarySvg.style.willChange = 'transform';
                }
            }

            if (this.$primarycontainer) {
                this.$primarycontainer.style.transition = 'top 300ms ease-in-out';
            }

            if (this.$primaryText) {
                this.$primaryText.firstElementChild.style = 'will-change: transform, opacity; transition: all 300ms ease-in-out; opacity: 1; transform: translateY(0px);';
            }

            if (this.$secondaryText) {
                if (this.isBreakpoint('lg+')) {
                    this.$secondaryText.style = 'will-change: transform, opacity; transition: all 300ms ease-in-out; opacity: 0; transform: translateY(50px);';
                }
            }

            document.addEventListener('header:top', this.headerTop, false);
            document.addEventListener('header:absolute', this.headerAbsolute, false);
            document.addEventListener('header:fixed', this.headerFixed, false);
            window.requestAnimationFrame(() => {
                this.$node.style.setProperty('--rotate', '0deg');
                this.loop();
                this.$content.classList.add('opacity-1');
                this.$content.classList.remove('opacity-0');
            });
        },
        resized() {
            this.windowH = window.innerHeight;
            this.secondaryRevealPos = Math.round(this.windowH * this.secondayRevealPercent);
        },
        mediaQueryUpdated() {
            this.desktop = this.isBreakpoint('lg+');
            if (this.$secondaryText) {
                if (this.isBreakpoint('lg+')) {
                    this.$secondaryText.style = 'will-change: transform, opacity; transition: all 300ms; opacity: 0; transform: translateY(50px);';
                } else {
                    this.$secondaryText.style = '';
                }
            }
        },
        destroy() {
            this.intersecting = false;
            this.io.disconnect();

            document.removeEventListener('header:top', this.headerTop);
            document.removeEventListener('header:absolute', this.headerAbsolute);
            document.removeEventListener('header:fixed', this.headerFixed);
        },
    },
);

export default heroFeature;
