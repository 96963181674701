import {createBehavior} from '@area17/a17-behaviors';

const formAssemblyForm = createBehavior(
    'formAssemblyForm',
    {
        deleteStyles(element) {
            // Remove styles from button
            if (element.querySelector('.primaryAction')) {
                element.querySelector('.primaryAction').className = '';
            }
            element.getElementsByClassName('wForm')[0].style.backgroundColor =
                'transparent';
        },
        removeElements(element) {
            // remove brs
            const brs = element.querySelectorAll('br');
            for (let i = 0; i < brs.length; i++) {
                brs[i].remove();
            }
        },
        applyStyles(element) {
            // title
            const title = element.querySelector('.wFormTitle');
            if (title) {
                title.classList.add('f-heading-2');
            }

            // form fields
            const fields = element.querySelectorAll('.oneField');

            for (let i = 0; i < fields.length; i++) {
                fields[i].dataset.component = 'form:text';
                fields[i].classList.add('mt-24', 'lg:mt-40', 'w-full');
            }

            // inputWrappers
            const inputWrappers = element.querySelectorAll('.inputWrapper');
            for (let i = 0; i < inputWrappers.length; i++) {
                const hint = inputWrappers[i].querySelector('.hint');
                const child = inputWrappers[i].children[0];
                const parent = child.parentNode;
                const grandParent = parent.parentNode;
                grandParent.appendChild(child);
                if (hint) {
                    hint.classList.add('f-ui-2', 'block');
                    grandParent.appendChild(hint);
                }
                if (parent) {
                    parent.remove();
                }
            }

            // sections
            const sections = element.querySelectorAll('.section.inline.group');
            for (let i = 0; i < sections.length; i++) {
                sections[i].classList.add(
                    'flex',
                    'flex-wrap',
                    'justify-between',
                    'clear-left',
                );
                const fields = sections[i].querySelectorAll('.oneField');
                if (fields.length == 2) {
                    fields.forEach((field) => {
                        field.classList.add('w-full', `md:w-half-inc-gutter`);
                    });
                }
            }

            // if captcha
            const captcha = element.querySelector('#google-captcha');
            if (captcha) {
                // append just before parent
                const parent = captcha.parentNode;
                const grandparent = parent.parentNode;
                grandparent.insertBefore(captcha, parent);
                captcha.classList.add('w-full', 'md:w-half-inc-gutter');
            }

            // buttons
            const buttons = element.querySelectorAll('.wfPagingButtons input');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].classList.add('btn', 'btn-primary');
            }

            // form
            const form = element.querySelector('form');
            form.dataset.component = 'form:form';
            form.classList.add('-mt-24', 'lg:-mt-40');

            // primary action
            const primaryAction = element
                .querySelector('.actions')
                .classList.add('flex', 'mt-40');
        },
    },
    {
        init() {
            const element = this.$node;
            this.deleteStyles(element);
            this.removeElements(element);
            this.applyStyles(element);
        },
    },
);

export default formAssemblyForm;
