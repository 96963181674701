import {createBehavior} from '@area17/a17-behaviors';

const critical = createBehavior('critical',
    {
        setBar(val) {
            this.bar = val;
        },
        alert(val) {
            console.log('output from critical alert method:', val || this.foo);
        },
    },
    {
        init() {
            console.log('#### critical added', this.$node);
            this.foo = 'bar';
            this.setBar('baz');
        },
        destroy() {
            console.log('#### destroy critical #');
        },
    },
);

export default critical;

