const modifyDom = () => {

    loadScripts('https://www.queryly.com/js/queryly.v4.js', function() {
        if (queryly) {
            queryly.init('3c3a222606454eef', document.querySelectorAll('.container'));

            setTimeout(() => {
                loadScripts('https://www.queryly.com/js/foundation-advanced-search.js', function() {
                    const isElementLoaded = async selector => {
                        while ( document.querySelector(selector) === null) {
                            await new Promise( resolve =>  requestAnimationFrame(resolve) )
                        }
                        return document.querySelector(selector);
                    };

                    isElementLoaded('#faceteddata .btn-secondary').then((selector) => {
                        // Run code here.
                        const newButtonEl = document.createElement('button');
                        const facetedDataParentDiv = document.querySelector('#faceteddata div');
                        const classList = ['w-full', 'btn-secondary'];

                        newButtonEl.classList.add(...classList);
                        newButtonEl.setAttribute('tabindex', '0');
                        newButtonEl.setAttribute('onclick', 'searchPage.dofacetedsearch(0,\'\',\'\');return false;');
                        const buttonText = document.createTextNode('Clear Filters');
                        newButtonEl.appendChild(buttonText);
                        facetedDataParentDiv.appendChild(newButtonEl);
                        selector.remove();
                    });
                });
            }, 1000);
        }
    });


};

const loadScripts = (url, callback) => {
    const e = document.createElement('script');
    e.src = url;
    e.type = 'text/javascript';
    e.addEventListener('load', callback);
    document.getElementsByTagName('body')[0].appendChild(e);
};

export default modifyDom;