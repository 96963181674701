import {createBehavior} from '@area17/a17-behaviors';
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

const slider = createBehavior(
    'slider',
    {
        initSlider() {
            const breakpoints = {};

            Object.keys(window.USCC.structure.breakpoints).forEach(key => {
                if (key !== 'sm') {
                    breakpoints[parseInt(window.USCC.structure.breakpoints[key])] = {
                        slidesPerView: (key === 'md') ? 2 : (this.options.layout === 'half' ? 1.5 : 3),
                        spaceBetween: parseInt(window.USCC.structure.gutters.inner[key]),
                    };
                }
            });

            const config = {
                modules: [Navigation, Pagination],
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                speed: 500,
                spaceBetween: 24,
                breakpoints: breakpoints,
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
            };

            this.swiper = new Swiper(this.container, config);
        },
        triggerUpdate() {
            if (this.swiper) {
                this.swiper.update();
            } else {
                this.initSlider();
            }
        },
    },
    {
        init() {
            window.addEventListener('tab-update', this.triggerUpdate);
            this.container = this.getChild('container');
            this.initSlider();
        },
        destroy() {
            window.removeEventListener('tab-update', this.triggerUpdate);
        },
    },
);

export default slider;
