import {createBehavior} from '@area17/a17-behaviors';

const listingLoad = createBehavior(
    'listingLoad',
    {
        initSelect() {
            this.select
                .querySelector('select')
                .addEventListener('change', (e) => {
                    this.getData();
                });
        },

        initLoadMore() {
            this.trigger.addEventListener('click', () => {
                this.getData();
            });
        },

        getData() {
            const filterValue = this.select.querySelector('select').value;
            console.log('get data from select and ajax');
            // ajaxRequest({
            //     url: this.url,
            //     data: {
            //         param1: filterValue,
            //     },
            //     onSuccess: (data) => {
            //         // append data to this.content
            //         // data would need to be html list items
            //         this.content.innerHTML += data
            //     },
            //     onError: (e) => {
            //         console.log(e)
            //     },
            // })
        },
    },

    {
        init() {
            this.url = this.options.url;
            this.trigger = this.getChild('trigger');
            this.content = this.getChild('content');
            this.select = this.getChild('select');

            this.initSelect();
            this.initLoadMore();
        },
    },
);

export default listingLoad;
