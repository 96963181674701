import {createBehavior} from '@area17/a17-behaviors';

const heroIntro = createBehavior(
    'heroIntro',
    {
        reveal() {
            if (!this.desktop) {
                return;
            }
            if (window.pageYOffset < 1) {
                this.$title.classList.add('before:bg-accent-primary', 'text-accent-secondary');
            } else {
                this.$title.classList.remove('before:bg-accent-primary', 'text-accent-secondary');
            }
        },
    },
    {
        init() {
            this.$title = this.getChild('title');
            this.desktop = this.isBreakpoint('lg+');
            window.addEventListener('scroll', this.reveal, {passive: true});
            this.reveal();
        },
        mediaQueryUpdated() {
            this.desktop = this.isBreakpoint('lg+');
            if (!this.desktop) {
                this.$title.classList.add('before:bg-accent-primary', 'text-accent-secondary');
            }
            this.reveal();
        },
        resized() {
            this.reveal();
        },
        destroy() {
            window.removeEventListener('scroll', this.reveal);
        },
    },
);

export default heroIntro;
